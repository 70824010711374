import {
  useShow,
  IResourceComponentsProps,
  useList,
} from "@pankod/refine-core";

import { useNavigate } from "react-router-dom";

import "./style.css";

import {
  Typography,
  Row,
  Col,
  Card,
  Space,
  Grid,
  TextField,
  NumberField,
  DateField,
  Tag,
  EditButton,
  DeleteButton,
} from "@pankod/refine-antd";

import { IOrder } from "interfaces/order";
import { IProduct } from "interfaces/product";

const { useBreakpoint } = Grid;

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const { xl } = useBreakpoint();
  const { queryResult } = useShow<IOrder>();
  const { refetch: createRefetch, data: productDataResult } = useList<IProduct>(
    {
      resource: "product/",
      config: {
        pagination: { current: 1, pageSize: 1000 },
        hasPagination: true,
      },
    }
  );

  const navigate = useNavigate();

  // @ts-ignore
  const productList = productDataResult?.data?.results;

  const { data } = queryResult;
  const order = data?.data;

  let orderProductList: IProduct[] = [];

  order?.orderItems.forEach((element) => {
    orderProductList.push(productList?.find((i: any) => i.id == element));
  });

  const getOrderStatus = (status: string) => {
    switch (status) {
      case "PENDING":
        return "Beklemede";
        break;

      case "SHIPPED":
        return "Kargoya Verildi";
        break;

      case "DELIVERED":
        return "Teslim Edilmiş";
        break;

      case "CANCELLED":
        return "İptal Edilmiş";
        break;

      case "PREPARING":
        return "Hazırlanıyor";
        break;

      default:
        return "Beklemede";
        break;
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} justify={"center"} className="user-list-box">
        <Col xl={12} lg={24} xs={24} className="user-list-info">
          <Card
            bordered={false}
            style={{ height: "100%" }}
            className="user-list-info-card"
          >
            <Row style={{ marginBottom: "12px" }}>
              <Col span={24} style={{ textAlign: "end" }}>
                <Space>
                  <EditButton size="small" recordItemId={order?.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={order?.id}
                    hideText
                    onSuccess={() => navigate("/order")}
                  />
                </Space>
              </Col>
            </Row>
            <Space
              direction="vertical"
              style={{ width: "100%", height: "100%" }}
            >
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                  textAlign: xl ? "unset" : "center",
                }}
              >
                <Typography.Title level={3} style={{ textAlign: "center" }}>
                  Sipariş Özeti
                </Typography.Title>

                <div className="product-detail">
                  {orderProductList &&
                    orderProductList.map((item) => (
                      <Row
                        gutter={[20, 0]}
                        style={{
                          padding: "10px",
                          margin: "10px 0",
                          borderBottom: "2px solid #f5f5f5",
                        }}
                        align="middle"
                      >
                        <Col span={4}>
                          <img width={50} src={item?.imageOne}></img>
                        </Col>
                        <Col span={10}>
                          <Typography.Text strong>
                            {item?.productName}
                          </Typography.Text>
                        </Col>
                        <Col span={10} style={{ textAlign: "end" }}>
                          <NumberField
                            value={`${item?.price}`}
                            options={{ style: "currency", currency: "TRY" }}
                            style={{ fontSize: "12px" }}
                          />
                        </Col>
                      </Row>
                    ))}
                </div>

                <div className="order-detail">
                  <Typography.Text className="order-info-item">
                    <span className="order-info-item_title">
                      Sipariş Tarihi:
                    </span>{" "}
                    <p className="order-info-item_text">
                      <DateField value={order?.orderDate} format="LL" />
                    </p>
                  </Typography.Text>

                  <Typography.Text className="order-info-item">
                    <span className="order-info-item_title">Kargo No:</span>{" "}
                    <p className="order-info-item_text">{`${order?.orderCargoNumber}`}</p>
                  </Typography.Text>

                  <Typography.Text className="order-info-item">
                    <span className="order-info-item_title">Şehir:</span>{" "}
                    <p className="order-info-item_text">{`${order?.orderCity}`}</p>
                  </Typography.Text>

                  <Typography.Text className="order-info-item">
                    <span className="order-info-item_title">Posta Kodu:</span>{" "}
                    <p className="order-info-item_text">{`${order?.orderZip}`}</p>
                  </Typography.Text>

                  <Typography.Text className="order-info-item">
                    <span className="order-info-item_title">Adres:</span>{" "}
                    <p className="order-info-item_text">{`${order?.orderAddress}`}</p>
                  </Typography.Text>

                  <Typography.Text className="order-info-item">
                    <span className="order-info-item_title">Ürün Adet:</span>{" "}
                    <p className="order-info-item_text">
                      {`${order?.orderItems.length} adet`}
                    </p>
                  </Typography.Text>

                  <Typography.Text className="order-info-item">
                    <span className="order-info-item_title">Toplam:</span>{" "}
                    <p className="order-info-item_text">
                      <NumberField
                        value={`${order?.orderTotal}`}
                        options={{ style: "currency", currency: "TRY" }}
                      />
                    </p>
                  </Typography.Text>

                  <Typography.Text className="order-info-item">
                    <p className="order-info-item_status">
                      <Tag
                        className="tag tag-2x"
                        color={
                          order?.orderStatus === "CANCELLED"
                            ? "red"
                            : order?.orderStatus === "DELIVERED"
                            ? "success"
                            : order?.orderStatus === "SHIPPED"
                            ? "blue"
                            : order?.orderStatus === "PREPARING"
                            ? "warning"
                            : order?.orderStatus === "PENDING"
                            ? "default"
                            : "default"
                        }
                      >
                        {getOrderStatus(
                          order?.orderStatus ? order?.orderStatus : "PENDING"
                        )}
                      </Tag>
                    </p>
                  </Typography.Text>
                </div>
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
};
