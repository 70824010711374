import React from "react";
import {
  useUpdate,
  useNavigation,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  Icons,
  Space,
  Button,
  Avatar,
  Rate,
  Typography,
  Dropdown,
  Menu,
  Tag,
} from "@pankod/refine-antd";

import { IEvaluation } from "interfaces/session";

export const EvaluationList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();

  const { tableProps } = useTable<IEvaluation>({});

  const { mutate, isLoading } = useUpdate<IEvaluation>();

  const handleUpdate = (id: number, status: IEvaluation["isApproved"]) => {
    mutate({
      resource: "session/evaluation",
      id: id,
      values: { isApproved: status },
    });
  };

  const moreMenu = (id: number) => (
    <Menu mode="vertical">
      <Menu.Item
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.CheckCircleOutlined
            style={{
              color: "#52c41a",
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        onClick={() => handleUpdate(id, true)}
      >
        {"Onayla"}
      </Menu.Item>
      <Menu.Item
        style={{
          fontSize: 15,
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
        }}
        icon={
          <Icons.CloseCircleOutlined
            style={{
              color: "#EE2A1E",
              fontSize: 17,
            }}
          />
        }
        onClick={() => handleUpdate(id, false)}
      >
        {"Reddet"}
      </Menu.Item>
    </Menu>
  );

  // @ts-ignore
  const data = tableProps?.dataSource?.results;

  return (
    <List
    >
      <Table dataSource={data}>
        <Table.Column dataIndex={"customer"} title={"Müşteri"}
          render={(value) => (
            <Button
              onClick={() => {
                show("customer", value);
              }}
              type="text"
            >
              {value}
            </Button>
          )}/>

        <Table.Column dataIndex={"isReported"} title={"Rapor Durumu"} render={(value) => (
            <Tag className="tag tag-2x" color={value ? 'error' : 'success'}>
                {value ? "Olumsuz" : "Olumlu"}
            </Tag>
          )}/>

        <Table.Column dataIndex={"isApproved"} title={"Onaylı"} render={(value) =>
            {
               return value ? <Typography.Text type="success"><Icons.CheckOutlined/></Typography.Text> : <Typography.Text type="danger"><Icons.CloseOutlined/></Typography.Text>
            }
          }/>
        <Table.Column
          dataIndex={"session"}
          title={"Ders"}
          render={(value) => (
            <Button
              onClick={() => {
                show("session/group", value);
              }}
              type="text"
            >
              {value}
            </Button>
          )}
        />
        <Table.Column width={250} dataIndex="comment" title={"Yorum"} render={(value) => {
            return value !== null ? value : <Typography.Text style={{color: "tomato"}}>Yorum yok</Typography.Text>
        }}/>
        <Table.Column
          dataIndex="rating"
          title={"Değerlendirme"}
          align="center"
          render={(value) => (
            <Space
              direction="vertical"
              style={{
                rowGap: 0,
              }}
            >
               {/*<Typography.Text*/}
               {/*                 style={{*/}
               {/*                     fontSize: 31,*/}
               {/*                     fontWeight: 800,*/}
               {/*                 }}*/}
               {/*             >*/}
               {/*                 {value}*/}
               {/*             </Typography.Text> */}
              <Rate
                character={<Icons.StarFilled />}
                disabled
                value={value}
                style={{
                  color: "#FA8C16",
                }}
              />
            </Space>
          )}
        />
        <Table.Column<IEvaluation>
          title={"Seçenekler"}
          key="actions"
          render={(record) => (
            <Dropdown overlay={moreMenu(record.id)} trigger={["click"]}>
              <Icons.MoreOutlined
                style={{
                  fontSize: 24,
                }}
              />
            </Dropdown>
          )}
          fixed="right"
        />
      </Table>
    </List>
  );
};
