import { AuthProvider } from "@pankod/refine-core";
import axios from "axios";
import { toast } from "react-toastify";

const authProvider: AuthProvider = {
  login: async ({ username, password, remember }) => {
    const userData = { username, password };
    let authResult = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/token/`,
      userData
    ).catch(err => {
      toast.error("Kullanıcı adı veya şifre hatalı!")
      throw new Error(err);
    })
    
    if (authResult.status === 200) {
      const saveUserData = {
        username: userData?.username,
        token: authResult?.data?.token,
      };
      localStorage.setItem("monaxe", JSON.stringify(saveUserData));
      toast.success("Giriş başarılı")
      return Promise.resolve();
    }
  },
  logout: () => {
    localStorage.removeItem("monaxe");
    return Promise.resolve("/login");
  },
  checkError: () => Promise.resolve(),
  checkAuth: () =>
    JSON.parse(localStorage.getItem("monaxe") as any)
      ? Promise.resolve()
      : Promise.reject(),
  getPermissions: () => Promise.resolve(["admin"]),
};

export default authProvider;
