import { Refine } from "@pankod/refine-core";
import {
  ReadyPage,
  ErrorComponent,
  Menu,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";

import "./styles/main.css"

import { SimpleRestDataProvider } from "./providers/dataProvider";
import { auditLogProvider } from "./providers/auditLogProvider";
import routerProvider from "@pankod/refine-react-router-v6";
import {resources} from "./routes/resources"
import authProvider from "./providers/authProvider";

import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { Login } from "./pages/login/login";

const { Link } = routerProvider;

const API_URL = process.env.REACT_APP_BASE_URL as string;

function App() {
  return (
    <Refine
      authProvider={authProvider}
      auditLogProvider={auditLogProvider}
      reactQueryDevtoolConfig={false}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <Login />,
            path: "/login",
          },
          {
            element: <Login />,
            path: "/",

          },
        ],
      }}
      dataProvider={SimpleRestDataProvider(API_URL)}
      resources={resources}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
    />
  );
}

export default App;
