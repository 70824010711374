import { useEffect } from "react";

import { useForm, Input, Button, Tag } from "@pankod/refine-antd";

import { ITrainer } from "interfaces/trainer";

interface CertificateProps {
  certificateItem: string;
  certificatesArr: string[] | [];
  setCertificateItem: (value: string) => void;
  setCertificatesArr: (value: string[]) => void;
}

export const CertificatesList: React.FC<CertificateProps> = (props) => {
  const { formProps } = useForm<ITrainer>();

  // CERTIFICATES EDIT START
  const certificatesList = formProps?.initialValues?.certificates;

  const addCertificate = (certificateName: string) => {
    if (!certificateName.length) {
      return;
    }

    const certificateCheck = props?.certificatesArr.find((i: string) => {
      return i === certificateName;
    });

    if (certificateCheck) {
      return props?.setCertificateItem("");
    }

    props?.setCertificatesArr([
      ...props?.certificatesArr,
      props?.certificateItem,
    ]);
    props?.setCertificateItem("");
  };

  const delCertificate = (certificateName: string) => {
    if (!props?.certificatesArr.length) {
      return;
    }
    const newCertificatesList = props?.certificatesArr.filter((i: string) => {
      return i !== certificateName;
    });
    props?.setCertificatesArr(newCertificatesList);
  };

  const getCertList = () => {
    const certListResult = formProps?.initialValues?.certificates;
    if (certListResult) {
      props?.setCertificatesArr(certListResult);
    }
  };
  // CERTIFICATES EDIT END

  useEffect(() => {
    getCertList();
  }, [certificatesList]);
  return (
    <div className="trainer-certificates-edit-box">
      <Input
        value={props?.certificateItem}
        onChange={(e) => props?.setCertificateItem(e.target.value)}
        placeholder="Sertifika Adı"
      ></Input>
      <Button
        type="primary"
        danger
        style={{ margin: "20px auto" }}
        className="trainer-edit-add-button"
        onClick={() => addCertificate(props?.certificateItem)}
        size="small"
      >
        Sertifika Ekle
      </Button>
      <div className="certificates-list">
        {props?.certificatesArr && props?.certificatesArr.length > 0 ? (
          props?.certificatesArr.map((cer: string, index: any) => {
            return (
              <Tag
                onDoubleClick={() => delCertificate(cer)}
                color="geekblue"
                className="tag tag-2x certificate-tag-box"
                style={{ display: "block", margin: "15px 0" }}
                key={index}
              >
                <div className="certificate-tag">
                  <span className="tag-certificate">{cer}</span>
                  <span className="tag-delete">Silmek için çift tıkla!</span>
                </div>
              </Tag>
            );
          })
        ) : (
          <span className="certificates-message certificates-message-center">
            Eklenmiş bir sertifika yok!
          </span>
        )}
      </div>
    </div>
  );
};
