import {
  useTable,
  Row,
  Col,
  Icons,
  Statistic,
  Progress,
  List,
} from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";

import {
  GiBiceps,
  GiRunningShoe,
  GiTeamIdea,
  GiTeamDowngrade,
  GiShoppingCart,
  GiStarsStack,
} from "react-icons/gi";

import { ICustomer } from "interfaces/customer";
import { ITrainer } from "interfaces/trainer";
import { useEffect, useState } from "react";

import UserList from "components/dashboard/userList";

import "./style.css";

export const DashboardList: React.FC = () => {
  const trainerDataResult = useList<ITrainer>({
    resource: "trainer",
  });

  const customerDataResult = useList<ICustomer>({
    resource: "customer",
    config: {
      pagination: { current: 1, pageSize: 5 },
      hasPagination: true,
      sort: [{ order: "desc", field: "user__date_joined" }],
    },
  });

  const personalSessionDataResult = useList<ITrainer>({
    resource: "session/personal",
  });

  const groupSessionDataResult = useList<ITrainer>({
    resource: "session/group",
  });

  const trainerData = trainerDataResult.data;
  const customerData = customerDataResult.data;
  const personalSessionData = personalSessionDataResult.data;
  const groupSessionData:any = groupSessionDataResult.data;


  useEffect(() => {}, []);

  return (
    <>
      <Row justify="space-between" className="session-item-row" gutter={[0, 0]}>
        <Col xs={24} lg={8} className="progress-box">
          <Progress
            strokeColor={{
              "0%": "#e67575",
              "100%": "#003cff",
            }}
            type="circle"
            percent={80}
            width={150}
          />
          <Statistic
            title="Özel Ders / Grup Ders"
            value={personalSessionData?.total}
            suffix={`/ ${groupSessionData?.total}`}
            style={{ margin: "20px 0" }}
          />
        </Col>
        <Col xs={24} lg={8} className="progress-box">
          <Progress
            strokeColor={{
              "0%": "#ff8800",
              "100%": "#68d076",
            }}
            type="circle"
            percent={70}
            width={150}
          />
          <Statistic
            title="Derslere Katılma Oranı"
            value={302}
            suffix="/ 455"
            style={{ margin: "20px 0" }}
          />
        </Col>
        <Col xs={24} lg={8} className="progress-box">
          <Progress
            strokeColor={{
              "0%": "#fffb00",
              "100%": "#d0a668",
            }}
            type="circle"
            percent={92}
            width={150}
          />
          <Statistic
            title="Toplam Değerlendirme"
            value={1198}
            suffix="/ 1410"
            prefix={<Icons.LikeOutlined />}
            style={{ margin: "20px 0" }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} xs={24} lg={16}>
          <Row gutter={[16, 16]} style={{ margin: "16px 0" }}>
            <Col span={8} xs={24} lg={8}>
              <div className="session-item dashboard">
                <Statistic
                  title="Eğitmen Sayısı"
                  value={trainerData?.total}
                  prefix={<GiBiceps className="dashboard-icon" />}
                />
              </div>
            </Col>

            <Col span={8} xs={24} lg={8}>
              <div className="session-item dashboard">
                <Statistic
                  title="Kullanıcı Sayısı"
                  value={customerData?.total}
                  prefix={<GiRunningShoe className="dashboard-icon" />}
                />
              </div>
            </Col>

            <Col span={8} xs={24} lg={8}>
              <div className="session-item dashboard">
                <Statistic
                  title="Özel Ders Sayısı"
                  value={personalSessionData?.total}
                  prefix={<GiTeamIdea className="dashboard-icon" />}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ margin: "16px 0" }}>
            <Col span={8} xs={24} lg={8}>
              <div className="session-item dashboard">
                <Statistic
                  title="Grup Dersi Sayısı"
                  value={groupSessionData?.total}
                  prefix={<GiTeamDowngrade className="dashboard-icon" />}
                />
              </div>
            </Col>

            <Col span={8} xs={24} lg={8}>
              <div className="session-item dashboard">
                <Statistic
                  title="Satın Alma Sayısı"
                  value={455}
                  prefix={<GiShoppingCart className="dashboard-icon" />}
                />
              </div>
            </Col>

            <Col span={8} xs={24} lg={8}>
              <div className="session-item dashboard">
                <Statistic
                  title="Değerlendirme Sayısı"
                  value={1410}
                  prefix={<GiStarsStack className="dashboard-icon" />}
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={8} xs={24} lg={8}>
          <UserList customerData={customerData} />
        </Col>
      </Row>
    </>
  );
};
