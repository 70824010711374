import { Icons } from "@pankod/refine-antd";

import { DashboardList } from "../pages/dashboard";
import { TrainerList, TrainerShow, TrainerEdit } from "../pages/trainers";
import { CustomerList, CustomerShow, CustomerEdit } from "../pages/customers";
import {
  CreateTraining,
  TrainingList,
  TrainingShow,
  TrainingEdit,
} from "../pages/training";

import {
  ProductCreate,
  ProductList,
  ProductEdit,
  ProductShow,
} from "../pages/products";

import { OrderList, OrderShow, OrderEdit } from "../pages/orders";

import { CategoryList } from "../pages/category";
import {
  GroupList,
  GroupShow,
  GroupEdit,
  PersonalList,
  PersonalShow,
  PersonalEdit,
  CreatePersonalSession,
  EvaluationList,
} from "pages/sessions/";
import { NotificationCreate, NotificationList } from "../pages/notifications";
import { TrainerCreate } from "../pages/trainers/create";

export const resources = [
  {
    name: "dashboard",
    icon: <Icons.LineChartOutlined />,
    options: {
      label: "Dashboard",
    },
    list: DashboardList,
  },
  {
    name: "trainer",
    icon: <Icons.SkinOutlined />,
    options: {
      label: "Trainers",
    },
    list: TrainerList,
    show: TrainerShow,
    edit: TrainerEdit,
    create: TrainerCreate,
    canDelete: true,
  },
  {
    name: "customer",
    icon: <Icons.AuditOutlined />,
    list: CustomerList,
    show: CustomerShow,
    edit: CustomerEdit,
    canDelete: true,
  },
  {
    name: "sessions",
    icon: <Icons.CalendarOutlined />,
  },
  {
    name: "session/personal",
    parentName: "sessions",
    icon: <Icons.UserOutlined />,
    options: {
      label: "Personal",
      route: "sessions/personal",
    },
    list: PersonalList,
    show: PersonalShow,
    edit: PersonalEdit,
    // create: CreatePersonalSession,
    canDelete: true,
  },
  {
    name: "session/group",
    parentName: "sessions",
    icon: <Icons.TeamOutlined />,
    options: {
      label: "Group",
      route: "sessions/group",
    },
    list: GroupList,
    show: GroupShow,
    edit: GroupEdit,
    // create: CreateCustomerSession,
    canDelete: true,
  },
  {
    name: "session/evaluation",
    parentName: "sessions",
    icon: <Icons.StarOutlined />,
    options: {
      label: "Review",
      route: "sessions/evaluation",
    },
    list: EvaluationList,
    show: PersonalShow,
    edit: PersonalEdit,
    // create: CreatePersonalSession,
    canDelete: true,
  },
  {
    name: "training",
    icon: <Icons.CalendarOutlined />,
  },
  {
    name: "session/group/training",
    parentName: "training",
    icon: <Icons.TeamOutlined />,
    options: {
      label: "Group",
      route: "training/group-training",
    },
    list: TrainingList,
    show: TrainingShow,
    edit: TrainingEdit,
    create: CreateTraining,
    canDelete: true,
  },
  {
    name: "category",
    parentName: "training",
    icon: <Icons.AppstoreOutlined />,
    options: {
      label: "Category",
      route: "training/category",
    },
    list: CategoryList,
    canDelete: true,
  },
  {
    name: "product",
    icon: <Icons.TagOutlined />,
    options: {
      label: "Products",
    },
    list: ProductList,
    show: ProductShow,
    edit: ProductEdit,
    create: ProductCreate,
    canDelete: true,
  },
  {
    name: "order",
    icon: <Icons.ShoppingCartOutlined />,
    options: {
      label: "Orders",
    },
    list: OrderList,
    show: OrderShow,
    edit: OrderEdit,
    canDelete: true,
  },
  {
    name: "notifications",
    icon: <Icons.NotificationFilled />,
    options: {
      label: "Notifications",
    },
    list: NotificationList,
    create: NotificationCreate,
    canDelete: true,
  },
];
