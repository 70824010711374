import {
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  Row,
  Col,
  ShowButton,
  NumberField,
  EditButton,
} from "@pankod/refine-antd";

import { IUserFilterVariables } from "interfaces";
import { IProduct } from "interfaces/product";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, sorter } = useTable<
    IProduct,
    HttpError,
    IUserFilterVariables
  >({
    resource: "product/",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q } = params;

      filters.push({
        field: "search",
        operator: "eq",
        value: q,
      });
      return filters;
    },
    syncWithLocation: false,
  });

  let listDataSource: any = tableProps?.dataSource;
  let listData = listDataSource?.results;

  return (
    <Row gutter={[16, 0]} style={{ display: "flex", flexDirection: "column" }}>
      <Col xl={24} xs={24}>
        <List>
          <Table {...tableProps} dataSource={listData} rowKey="id">
            <Table.Column
              align="center"
              key="imageOne"
              dataIndex={"imageOne"}
              render={(value) => <img className="product-image" src={value} />}
            />
            <Table.Column
              key="productName"
              dataIndex="productName"
              title={"Ürün Adı"}
            />
            <Table.Column
              key="price"
              dataIndex="price"
              title="Fiyat"
              render={(value) =>
                value && (
                  <NumberField
                    value={value}
                    options={{ style: "currency", currency: "TRY" }}
                  />
                )
              }
            />
            <Table.Column
              key="itemCount"
              dataIndex="itemCount"
              title={"Stok"}
              render={(value) => `${value} adet`}
            />
            <Table.Column<IProduct>
              render={(_, record) => (
                <>
                  <ShowButton hideText title="Detay" recordItemId={record.id}>
                    Detail
                  </ShowButton>{" "}
                  <EditButton
                    hideText
                    title="Detay"
                    recordItemId={record.id}
                  ></EditButton>
                </>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
