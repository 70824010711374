import {
  useShow,
  HttpError,
  IResourceComponentsProps,
  useList,
  useCustom,
  CrudFilters,
} from "@pankod/refine-core";

import { useNavigate } from "react-router-dom";

import "./style.css";

import {
  Typography,
  useTable,
  Avatar,
  Row,
  Col,
  Card,
  Space,
  Icons,
  List,
  Table,
  Grid,
  TextField,
  getDefaultSortOrder,
  NumberField,
  Popover,
  DateField,
  Rate,
  Tag,
  EditButton,
  DeleteButton,
  Progress,
  Statistic,
  ShowButton,
} from "@pankod/refine-antd";

import { IUserFilterVariables } from "interfaces";
import { IProduct } from "interfaces/product";

const { useBreakpoint } = Grid;

export const ProductShow: React.FC<IResourceComponentsProps> = () => {
  const { xl } = useBreakpoint();
  const { queryResult } = useShow<IProduct>();
  const navigate = useNavigate();

  const { data } = queryResult;
  const product = data?.data;

  const { tableProps, sorter } = useTable<
    IProduct,
    HttpError,
    IUserFilterVariables
  >({
    resource: `product/${product?.id}`,
    syncWithLocation: false,
  });

  let tableData = tableProps?.dataSource;

  const getCheckValue = (value: any) => {
    if (!value) {
      return "Belirtilmemiş!";
    }
    return value;
  };

  return (
    <>
      <Row gutter={[16, 16]} justify={"center"} className="user-list-box">
        <Col xl={12} lg={24} xs={24} className="user-list-info">
          <Card
            bordered={false}
            style={{ height: "100%" }}
            className="user-list-info-card"
          >
            <Row style={{ marginBottom: "12px" }}>
              <Col span={24} style={{ textAlign: "end" }}>
                <Space>
                  <EditButton size="small" recordItemId={product?.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={product?.id}
                    hideText
                    onSuccess={() => navigate("/product")}
                  />
                </Space>
              </Col>
            </Row>
            <Space
              direction="vertical"
              style={{ width: "100%", height: "100%" }}
            >
              <Space
                direction="vertical"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <img
                  className="show-product-image"
                  src={product?.imageOne}
                ></img>

                <Typography.Title level={3}>
                  {getCheckValue(product?.productName)}
                </Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                  textAlign: xl ? "unset" : "center",
                }}
              >
                <Space
                  direction="vertical"
                  style={{
                    textAlign: "center",
                    width: "100%",
                    borderTop: "2px solid #f5f5f5",
                    padding: "20px 0",
                  }}
                >
                  <Typography.Title level={5}>Ürün Açıklaması</Typography.Title>

                  <Typography.Text className="" style={{ textAlign: "center" }}>
                    {product?.description
                      ? product?.description
                      : "Belirtilmemiş!"}
                  </Typography.Text>
                </Space>

                <Typography.Text className="profile-info-item">
                  <Icons.DollarOutlined /> <span>Fiyat:</span>{" "}
                  {product?.price ? (
                    <NumberField
                      value={`${product?.price}`}
                      options={{ style: "currency", currency: "TRY" }}
                    />
                  ) : (
                    "Belirtilmemiş!"
                  )}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.SafetyCertificateOutlined /> <span>Stok:</span>{" "}
                  {product?.itemCount === 0 ? (
                    <Tag color={"red"} className="tag tag-2x" key={product?.id}>
                      {`${product?.itemCount} adet`}
                    </Tag>
                  ) : (
                    <Tag
                      color={"green"}
                      className="tag tag-2x"
                      key={product?.id}
                    >
                      {`${product?.itemCount} adet`}
                    </Tag>
                  )}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
};
