import { useEffect, useState } from "react";

import {
  List,
  Card,
  Col,
  Row,
  CreateButton,
  EditButton,
  DeleteButton,
  ShowButton,
  Space,
  Typography,
  Drawer,
  Create,
  Form,
  Radio,
  Input,
  Edit,
  Button,
  SaveButton,
  Spin
} from "@pankod/refine-antd";

import { useDrawerForm } from "@pankod/refine-antd";

import { useList, useCreate, useUpdate } from "@pankod/refine-core";

import { ICategory, ISubCategory } from "../../interfaces/training";

import { useNavigate } from "react-router-dom";

import "./style.css";

export const CategoryList: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [childrenDrawer, setChildrenDrawer] = useState<boolean>(false);
  const [childrenDrawerCreate, setChildrenDrawerCreate] = useState<boolean>(false);
  const [subCategoryObj, setSubCategoryObj] = useState<ICategory>();
  const [childrenDrawerObj, setChildrenDrawerObj] = useState<ISubCategory>();

  const { mutate: createMutate } = useCreate();
  const { mutate: updateMutate } = useUpdate();
  const navigate = useNavigate();

  const categoryDataResult = useList<ICategory>({
    resource: "category/main",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });
  // @ts-ignore
  const categoryData: Promise = categoryDataResult?.data?.data?.results;

  // Main Category Create Form
  const {
    formProps,
    drawerProps,
    show,
    saveButtonProps,
    form,
    redirect,
    close,
  } = useDrawerForm<ICategory>({
    action: "create",
    resource: "category/main",
  });

  // Sub Category Create Form
  const {
    formProps: formPropsSubCreate,
    saveButtonProps: saveButtonPropsSubCreate,
    form: formSubCreate,
  } = useDrawerForm<ISubCategory>({
    action: "create",
    resource: "category",
  });

  // Sub Category Edit Form
  const {
    formProps: formPropsSub,
    drawerProps: drawerPropsSub,
    show: showSub,
    saveButtonProps: saveButtonPropsSub,
    form: formSub,
    redirect: redirectSub,
    close: closeSub,
  } = useDrawerForm<ISubCategory>({
    action: "edit",
    resource: "category",
  });

  const createCategory = () => {
    const createFormData = new FormData();
    createFormData.append("name", form?.getFieldValue("name"));

    createMutate(
      {
        resource: "category/main",
        values: createFormData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          close();
          return navigate("/training/category");
        },
      }
    );
  };

  // Main Category Edit Form
  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    show: editShow,
    saveButtonProps: editSaveButtonProps,
    deleteButtonProps: editDeleteButtonProps,
    id,
  } = useDrawerForm<ICategory>({
    action: "edit",
    resource: "category/main",
    redirect: false,
    onMutationSuccess: () => {
      navigate("/training/category");
    },
  });

  // Sub Category
  const showDrawer = (mainCategory: ICategory) => {
    setSubCategoryObj(mainCategory);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showChildrenDrawer = (childrenDrawerItem: ISubCategory) => {
    setChildrenDrawerObj(childrenDrawerItem);
    formSub?.setFieldsValue({ name: childrenDrawerItem.name });
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawerObj({} as ISubCategory);
    setChildrenDrawer(false);
  };

  const showChildrenDrawerCreate = () => {
    setChildrenDrawerCreate(true);
  };

  const onChildrenDrawerCloseCreate = () => {
    setChildrenDrawerObj({} as ISubCategory);
    formSubCreate?.setFieldsValue({ name: "" });
    setChildrenDrawerCreate(false);
  };

  const editSubCategory = () => {
    const updateSubFormData = new FormData();
    updateSubFormData.append("name", formSub?.getFieldValue("name"));
    updateMutate(
      {
        resource: "category",
        values: updateSubFormData,
        id: childrenDrawerObj?.id as any
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        },
      }
    );
  };

  const createSubCategory = () => {
    const createFormData = new FormData();
    createFormData.append("name", formSubCreate?.getFieldValue("name"));
    createFormData.append("mainCategory", subCategoryObj?.id as any );

    createMutate(
      {
        resource: "category",
        values: createFormData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        },
      }
    );
  }

  return (
    <div className="category-box">
      <List
        createButtonProps={{
          onClick: () => {
            show();
          },
        }}
      >
        <div className="site-card-wrapper">
          <Row gutter={32} justify="center">
            <div className="create-category-form">
              <Drawer {...drawerProps} placement="left">
                <Create
                  saveButtonProps={saveButtonProps}
                  title="Ana Kategori Oluştur"
                >
                  <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={() => createCategory()}
                  >
                    <Form.Item
                      label="Kategori Adı:"
                      name="name"
                      hasFeedback
                      rules={[
                        { required: true, message: "Kategori adı zorunlu!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Form>
                </Create>
              </Drawer>
            </div>
            <div className="edit-category-form">
              <Drawer {...editDrawerProps} placement="left">
                <Edit
                  saveButtonProps={{ ...editSaveButtonProps }}
                  deleteButtonProps={{
                    ...editDeleteButtonProps,
                    resourceNameOrRouteName: "category/main",
                  }}
                  recordItemId={id}
                  title="Edit Main Category"
                >
                  <Form {...editFormProps} layout="vertical">
                    <Form.Item label="Title" name="name">
                      <Input />
                    </Form.Item>
                  </Form>
                </Edit>
              </Drawer>
            </div>
            {categoryData &&
              categoryData.map((i: ICategory, index: number) => {
                return (
                  <Col span={4} key={index} className="category-card-box">
                    <Card
                      title={i.name}
                      className="category-card"
                      bordered={false}
                    >
                      <Typography.Text strong>
                        {i.subcategories.length} Alt Kategori
                      </Typography.Text>
                      <Space className="action-box">
                        <ShowButton
                          className="category-actions"
                          hideText
                          recordItemId={i.id}
                          onClick={() => showDrawer(i)}
                        />

                        <EditButton
                          className="category-actions"
                          hideText
                          recordItemId={i.id}
                          onClick={() => editShow(i.id)}
                        />

                        <DeleteButton
                          className="category-actions"
                          hideText
                          recordItemId={i.id}
                          resourceNameOrRouteName="category/main"
                        />
                      </Space>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </div>
      </List>

      <div className="sub-cagetory-box">
        <Drawer width={520} closable={false} onClose={onClose} visible={open}>
          <List title="Alt Kategoriler" 
        createButtonProps={{
          onClick: () => {
            showChildrenDrawerCreate()
          },
          size: "small"
        }}>
            <div className="sub-category-list-box">
              {subCategoryObj?.subcategories &&
              subCategoryObj.subcategories.length > 0 ? (
                subCategoryObj.subcategories.map((i: ISubCategory) => {
                  return (
                    <div className="sub-category-item-box" key={i.id}>
                      <span>
                        <Typography.Text>{i.name}</Typography.Text>
                      </span>
                      <div className="sub-category-item-buttons">
                        <EditButton
                          className="category-actions"
                          hideText
                          recordItemId={i.id}
                          onClick={() => showChildrenDrawer(i)}
                        />

                        <DeleteButton
                          className="category-actions"
                          hideText
                          recordItemId={i.id}
                          resourceNameOrRouteName="category"
                          onSuccess={() => setTimeout(() => {window.location.reload()}, 1000)}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <Typography.Text>
                  Henüz alt kategori eklenmemiş!
                </Typography.Text>
              )}
            </div>
          </List>
          <hr className="hr" />
          {/* ALT KATEGORİ EKLE */}
          <Drawer
            title="Alt Kategori Ekle"
            width={320}
            closable={false}
            onClose={onChildrenDrawerCloseCreate}
            visible={childrenDrawerCreate}
          >
            <Form
              {...formPropsSubCreate}
              layout="vertical"
              onFinish={() => createSubCategory()}
            >
              <Form.Item
                name="name"
                hasFeedback
                rules={[
                  { required: true, message: "Alt kategori adı zorunlu!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Button className="sub-category-edit-button" htmlType="submit">
                Oluştur
              </Button>
            </Form>
          </Drawer>

          {/* ALT KATEGORİ DÜZENLE */}
          <Drawer
            title="Alt Kategori Düzenle"
            width={320}
            closable={false}
            onClose={onChildrenDrawerClose}
            visible={childrenDrawer}
          >
            <Form
              {...formPropsSub}
              layout="vertical"
              onFinish={() => editSubCategory()}
              initialValues={childrenDrawerObj}
            >
              <Form.Item
                name="name"
                hasFeedback
                rules={[
                  { required: true, message: "Alt kategori adı zorunlu!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Button className="sub-category-edit-button" htmlType="submit">
                Güncelle
              </Button>
            </Form>
          </Drawer>
        </Drawer>
      </div>
    </div>
  );
};
