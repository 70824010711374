import {
  useShow,
  HttpError,
  IResourceComponentsProps,
  useList,
  useCustom,
  CrudFilters,
} from "@pankod/refine-core";

import { useNavigate } from "react-router-dom";

import "./style.css";
import "../style.css";

import {
  Typography,
  useTable,
  Avatar,
  Row,
  Col,
  Card,
  Space,
  Icons,
  List,
  Table,
  Grid,
  TextField,
  getDefaultSortOrder,
  NumberField,
  Popover,
  DateField,
  Rate,
  Tag,
  EditButton,
  DeleteButton,
  Progress,
  Statistic,
  ShowButton,
} from "@pankod/refine-antd";

import { IUserFilterVariables } from "interfaces";
import { ITrainer } from "interfaces/trainer";

const { useBreakpoint } = Grid;

export const TrainerShow: React.FC<IResourceComponentsProps> = () => {
  const { xl } = useBreakpoint();
  const { queryResult } = useShow<ITrainer>();
  const navigate = useNavigate();

  const { data } = queryResult;
  const trainer = data?.data;

  const { tableProps, sorter } = useTable<
    ITrainer,
    HttpError,
    IUserFilterVariables
  >({
    resource: `session/?username=${trainer?.username}`,
    syncWithLocation: false,
  });

  let tableData = tableProps?.dataSource;

  const goLesson = (sessionId: number, sessionType: string) => {
    if (sessionType === "GroupSession") {
      navigate(`/sessions/group/show/${sessionId}`);
    }
    if (sessionType === "PersonalSession") {
      navigate(`/sessions/personal/show/${sessionId}`);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} className="user-list-box">
        <Col xl={6} lg={24} xs={24} className="user-list-info">
          <Card
            bordered={false}
            style={{ height: "100%" }}
            className="user-list-info-card"
          >
            <Row justify="end" style={{ marginBottom: "12px" }}>
              <Col xl={8}>
                <Space>
                  <EditButton size="small" recordItemId={trainer?.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={trainer?.id}
                    hideText
                  />
                </Space>
              </Col>
            </Row>
            <Space
              direction="vertical"
              style={{ width: "100%", height: "100%" }}
            >
              <Space
                direction="vertical"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Avatar size={120} src={trainer?.profilePhoto}></Avatar>
                <Typography.Title level={3}>
                  {trainer?.firstName} {trainer?.lastName}
                </Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                  textAlign: xl ? "unset" : "center",
                }}
              >
                <Typography.Text
                  style={{ textAlign: "center" }}
                  className="profile-info-item approved-item"
                >
                  <Tag
                    color={
                      trainer?.accountStatus === "approved" ? "blue" : "red"
                    }
                  >
                    {" "}
                    {trainer?.accountStatus === "approved" ? (
                      <Icons.CheckCircleOutlined />
                    ) : (
                      <Icons.ExclamationCircleOutlined />
                    )}{" "}
                    {trainer?.accountStatus === "approved"
                      ? "Onaylı Hesap"
                      : "Onaylı Değil"}
                  </Tag>{" "}
                  <Tag color={trainer?.isFeatured ? "blue" : "red"}>
                    {" "}
                    {trainer?.isFeatured ? (
                      <Icons.CheckCircleOutlined />
                    ) : (
                      <Icons.ExclamationCircleOutlined />
                    )}{" "}
                    {trainer?.isFeatured
                      ? "Öne Çıkarılmış"
                      : "Öne Çıkarılmamış"}
                  </Tag>
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.PhoneOutlined /> <span>Telefon:</span>{" "}
                  {trainer?.phoneNumber}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.MailOutlined /> <span>Email:</span>{" "}
                  {trainer?.email ? trainer.email : "Eklenmemiş"}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.UserOutlined /> <span>Cinsiyet:</span>{" "}
                  {trainer?.gender === "male"
                    ? "Erkek"
                    : trainer?.gender === "female"
                    ? "Kadın"
                    : trainer?.gender === "other"
                    ? "Diğer"
                    : "Belirtilmemiş"}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.ClockCircleOutlined /> <span>Deneyim:</span>{" "}
                  {`+${trainer?.experience} yıl`}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.DollarOutlined /> <span>Ücret:</span>{" "}
                  <NumberField
                    value={`${trainer?.price}`}
                    options={{ style: "currency", currency: "TRY" }}
                  />
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.CalendarOutlined />
                  <span>Son Aktivite:</span>
                  <DateField value={trainer?.lastActivity} format="LL" />
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.CalendarOutlined />
                  <span>Kayıt Tarihi:</span>
                  <DateField value={trainer?.dateJoined} format="LL" />
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.SafetyCertificateOutlined /> <span>Sertifikalar:</span>{" "}
                  <br />
                  {trainer?.certificates.length
                    ? trainer?.certificates.map((cert: any, index: any) => (
                        <Tag color="cyan" className="tag tag-2x" key={index}>
                          {cert}
                        </Tag>
                      ))
                    : <span className="certificates-message">Eklenmiş bir sertifika yok!</span>}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.SolutionOutlined /> <span>Uzmanlık Alanları:</span>{" "}
                  <br />
                  {trainer?.specialities.length
                    ? trainer?.specialities.map((special: any, index: any) => (
                        <Tag color="magenta" className="tag tag-2x" key={index}>
                          {special}
                        </Tag>
                      ))
                    : "yok"}
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <Row>
            <Col xl={24} className="user-list-statics">
              <Typography.Title
                style={{ margin: "8px 16px 0 16px", textAlign: "center" }}
                level={4}
              >
                Eğitmen Hakkında
              </Typography.Title>

              <div className="trainer-sessions-detail">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <div className="session-item">
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <Progress
                            strokeColor={{
                              "0%": "#e67575",
                              "100%": "#003cff",
                            }}
                            type="circle"
                            percent={72}
                          />
                          <Statistic
                            title="Aktif Gün / Haftalık"
                            value={5}
                            suffix="/ 7"
                            style={{ margin: "20px 0" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col span={8}>
                    <div className="session-item">
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <Progress
                            strokeColor={{
                              "0%": "#f5b46b",
                              "100%": "#68d076",
                            }}
                            type="circle"
                            percent={100}
                          />
                          <Statistic
                            title="Derslere Katılma Oranı"
                            // @ts-ignore
                            value={tableData?.count}
                            // @ts-ignore
                            suffix={` /${tableData?.count}`}
                            style={{ margin: "20px 0" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col span={8} style={{ textAlign: "center" }}>
                    <div className="session-item">
                      <Row>
                        <Col span={24}>
                          <Progress
                            strokeColor={{
                              "0%": "#fffb00",
                              "100%": "#d0a668",
                            }}
                            type="circle"
                            percent={92}
                          />
                          <Statistic
                            title="Toplam Değerlendirme"
                            value={1198}
                            suffix="/ 1410"
                            prefix={<Icons.LikeOutlined />}
                            style={{ margin: "20px 0" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                  <Col span={4}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Grup Dersi Sayısı"
                        value={25}
                        prefix={<Icons.TeamOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={4}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Bireysel Ders Sayısı"
                        value={57}
                        prefix={<Icons.UserOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={4}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="İptal Edilen Ders Sayısı"
                        value={0}
                        prefix={<Icons.CloseCircleOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={4}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Toplam Ders Süresi"
                        value={4920 + " dakika"}
                        prefix={<Icons.ClockCircleOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={4}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Toplam Müşteri"
                        value={960}
                        prefix={<Icons.UsergroupAddOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={4}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Olumsuz Değerlendirme"
                        value={212}
                        prefix={<Icons.DislikeOutlined />}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xl={24} className="user-list-sessions">
              <List
                title={"Derslerim"}
                pageHeaderProps={{
                  extra: <></>,
                }}
              >
                <Table
                  {...tableProps}
                  // @ts-ignore
                  dataSource={tableData?.results}
                  rowKey="id"
                >
                  <Table.Column
                    key="isCanceled"
                    dataIndex="isCanceled"
                    title="Durum"
                    render={(value) => (
                      <Tag color={value === true ? "error" : "success"}>
                        {value === true ? "KAPALI" : "AÇIK"}
                      </Tag>
                    )}
                  />

                  <Table.Column
                    key="groupTrainingName"
                    dataIndex="groupTrainingName"
                    title="Ders Grubu"
                  />

                  <Table.Column
                    key="resourcetype"
                    dataIndex="resourcetype"
                    title="Ders Türü"
                    render={(value) => (
                      <Tag
                        color={value === "GroupSession" ? "geekblue" : "pink"}
                      >
                        {value === "GroupSession" ? "Grup Dersi" : "Özel Ders"}
                      </Tag>
                    )}
                  />

                  <Table.Column
                    key="startTime"
                    dataIndex="startTime"
                    title="Başlama Tarihi"
                    render={(value) => (
                      <DateField format="LLL" locales="tr" value={value} />
                    )}
                  />
                  <Table.Column
                    key="endTime"
                    dataIndex="endTime"
                    title="Bitiş Tarihi"
                    render={(value) => (
                      <DateField format="LLL" locales="tr" value={value} />
                    )}
                  />

                  <Table.Column<ITrainer>
                    render={(_, record) => (
                      <ShowButton
                        title="Detay"
                        hideText
                        onClick={() =>
                          // @ts-ignore
                          goLesson(record?.id, record?.resourcetype)
                        }
                      ></ShowButton>
                    )}
                  />
                </Table>
              </List>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
