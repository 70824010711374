import { useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useList,
  useNavigation,
} from "@pankod/refine-core";

import { useNavigate } from "react-router-dom";

import "./style.css";
import "../style.css";

import {
  Typography,
  useTable,
  Avatar,
  Row,
  Col,
  Card,
  Space,
  Icons,
  List,
  Table,
  Grid,
  DateField,
  Tag,
  EditButton,
  DeleteButton,
  Button,
  Progress,
  Statistic,
  ShowButton,
  Drawer,
} from "@pankod/refine-antd";

import { ICustomer } from "interfaces/customer";
import { IDataSource } from "interfaces";
import { ISessionGroup } from "interfaces/session";

const { useBreakpoint } = Grid;

export const CustomerShow: React.FC<IResourceComponentsProps> = () => {
  const { xl } = useBreakpoint();
  const { queryResult } = useShow<ICustomer>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { show } = useNavigation();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const { data } = queryResult;
  const customer = data?.data;

  const { tableProps } = useTable({
    resource: `session/?username=${customer?.username}`,
    syncWithLocation: false,
  });

  const { dataSource: tableDataSource } = tableProps;
  const tableData: IDataSource | any = tableDataSource;

  // Get Favorite List
  const favoriteDataResult = useList<ISessionGroup>({
    resource: "session/group",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
      filters: [
        {
          field: "filtered_by",
          operator: "eq",
          value: customer?.id,
        },
      ],
    },
  });
  // @ts-ignore
  const favoriteData = favoriteDataResult?.data?.data?.results;

  const goLesson = (sessionId: number, sessionType: string) => {
    if (sessionType === "GroupSession") {
      // show() alternative method
      navigate(`/sessions/group/show/${sessionId}`);
    }
    if (sessionType === "PersonalSession") {
      show("sessions/personal", sessionId);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]} className="user-list-box">
        <Col xl={6} lg={24} xs={24} className="user-list-info">
          <Card
            bordered={false}
            style={{ height: "100%" }}
            className="user-list-info-card"
          >
            <Row justify="end" style={{ marginBottom: "12px" }}>
              <Col xl={6}>
                <Space>
                  <EditButton size="small" recordItemId={customer?.id} />
                  <DeleteButton
                    size="small"
                    recordItemId={customer?.id}
                    hideText
                  />
                </Space>
              </Col>
            </Row>
            <Space
              direction="vertical"
              style={{ width: "100%", height: "100%" }}
            >
              <Space
                direction="vertical"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <Avatar size={120} src={customer?.profilePhoto}></Avatar>
                <Typography.Title level={3} style={{ margin: "10px 0" }}>
                  {customer?.firstName} {customer?.lastName}
                </Typography.Title>
              </Space>
              <Space
                direction="vertical"
                style={{
                  width: "100%",
                  textAlign: xl ? "unset" : "center",
                }}
              >
                <Typography.Text className="profile-info-item">
                  <Icons.PhoneOutlined /> <span>Telefon:</span>{" "}
                  {customer?.phoneNumber}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.MailOutlined /> <span>Email:</span>{" "}
                  {customer?.email ? customer.email : "Eklenmemiş"}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.UserOutlined /> <span>Cinsiyet:</span>{" "}
                  {customer?.gender === "male"
                    ? "Erkek"
                    : customer?.gender === "female"
                    ? "Kadın"
                    : customer?.gender === "other"
                    ? "Diğer"
                    : "Belirtilmemiş"}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.ClockCircleOutlined /> <span>Yaş:</span>{" "}
                  {customer?.age}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.CheckOutlined /> <span>Aktif:</span>{" "}
                  {Boolean(customer?.isActive) ? "Evet" : "Hayır"}
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.CalendarOutlined />
                  <span>Son Aktivite:</span>
                  <DateField value={customer?.lastActivity} format="LL" />
                </Typography.Text>

                <Typography.Text className="profile-info-item">
                  <Icons.CalendarOutlined />
                  <span>Kayıt Tarihi:</span>
                  <DateField value={customer?.dateJoined} format="LL" />
                </Typography.Text>
              </Space>
            </Space>
          </Card>
        </Col>
        <Col xl={18} xs={24}>
          <Row>
            <Col xl={24} className="user-list-statics">
              <Typography.Title
                style={{ margin: "8px 16px 0 16px", textAlign: "center" }}
                level={4}
              >
                Müşteri Hakkında
              </Typography.Title>

              <div className="customer-sessions-detail">
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="session-item">
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <Progress
                            strokeColor={{
                              "0%": "#00ff9d",
                              "100%": "#003cff",
                            }}
                            type="circle"
                            percent={100}
                          />
                          <Statistic
                            title="Derslere Katılma Oranı"
                            value={tableData?.count}
                            suffix={` /${tableData?.count}`}
                            style={{ margin: "20px 0" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col span={12}>
                    <div className="session-item">
                      <Row>
                        <Col span={24} style={{ textAlign: "center" }}>
                          <Progress
                            strokeColor={{
                              "0%": "#fffb00",
                              "100%": "#d0a668",
                            }}
                            type="circle"
                            percent={90}
                          />
                          <Statistic
                            title="Toplam Değerlendirme"
                            value={18}
                            suffix="/ 20"
                            prefix={<Icons.LikeOutlined />}
                            style={{ margin: "20px 0" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                  <Col span={6}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Satın Alınan Ders Sayısı"
                        value={25}
                        prefix={<Icons.CheckCircleOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={6}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Derse Katılım Sayısı"
                        value={57}
                        prefix={<Icons.UserAddOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={6}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Olumlu Değerlendirme"
                        value={18}
                        prefix={<Icons.LikeOutlined />}
                      />
                    </div>
                  </Col>

                  <Col span={6}>
                    <div className="session-item session-item-small">
                      <Statistic
                        title="Olumsuz Değerlendirme"
                        value={2}
                        prefix={<Icons.DislikeOutlined />}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xl={24} className="user-list-sessions">
              <List
                title={"Derslerim"}
                pageHeaderProps={{
                  extra: (
                    <>
                      <Button
                        onClick={showDrawer}
                        icon={<Icons.HeartFilled />}
                        type="dashed"
                        danger
                      >
                        Favori Dersler
                      </Button>
                    </>
                  ),
                }}
              >
                <Table
                  {...tableProps}
                  dataSource={tableData?.results}
                  rowKey="id"
                >
                  <Table.Column
                    key="isCanceled"
                    dataIndex="isCanceled"
                    title="Durum"
                    render={(value) => (
                      <Tag color={value === true ? "error" : "success"}>
                        {value === true ? "KAPALI" : "AÇIK"}
                      </Tag>
                    )}
                  />

                  <Table.Column
                    key="resourcetype"
                    dataIndex="resourcetype"
                    title="Ders"
                    render={(value) => (
                      <Tag
                        color={value === "GroupSession" ? "geekblue" : "pink"}
                      >
                        {value === "GroupSession" ? "Grup Dersi" : "Özel Ders"}
                      </Tag>
                    )}
                  />

                  <Table.Column
                    key="trainer"
                    dataIndex="trainer"
                    title="Eğitmen"
                    render={(value) => `${value?.firstName} ${value?.lastName}`}
                  />

                  <Table.Column
                    key="startTime"
                    dataIndex="startTime"
                    title="Başlama Tarihi"
                    render={(value) => <DateField format="LLL" value={value} />}
                  />

                  <Table.Column
                    key="endTime"
                    dataIndex="endTime"
                    title="Bitiş Tarihi"
                    render={(value) => (
                      <DateField format="LLL" locales="tr" value={value} />
                    )}
                  />
                  <Table.Column<ICustomer>
                    render={(_, record: any) => (
                      <ShowButton
                        title="Detay"
                        hideText
                        onClick={() =>
                          goLesson(record?.id, record?.resourcetype)
                        }
                      ></ShowButton>
                    )}
                  />
                </Table>
              </List>
            </Col>
          </Row>
        </Col>
      </Row>

      <Drawer
        width={500}
        title="Favori Dersler"
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <List title={false} breadcrumb>
          {favoriteData && favoriteData.length > 0 ? (
            favoriteData.map((i: ISessionGroup) => {
              return (
                <Row
                  className="favorite-session-item"
                  justify="space-between"
                  align="middle"
                  key={i.id}
                >
                  <Typography.Text>{i.groupTrainingName}</Typography.Text>
                  <ShowButton
                    hideText
                    onClick={() => show("session/group", i.id)}
                  />
                </Row>
              );
            })
          ) : (
            <Typography.Text>Favori Ders Yok !</Typography.Text>
          )}
        </List>
      </Drawer>
    </>
  );
};
