import { useState } from "react";

import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  Typography,
  useImport,
  Button,
  Icons,
  Upload,
  Row,
  Col,
} from "@pankod/refine-antd";

import { useUpdate } from "@pankod/refine-core";

import { CertificatesList } from "../../components/trainer/certificates";
import { SpecialityList } from "../../components/trainer/speciality";

import type { UploadProps } from "@pankod/refine-antd";

import { ITrainer } from "interfaces/trainer";
import { ISpeciality } from "interfaces/trainer";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

const { ImportOutlined } = Icons;

export const TrainerEdit: React.FC = () => {
  const [certificateItem, setCertificateItem] = useState<string>("");
  const [certificatesArr, setCertificatesArr] = useState<string[]>([]);

  const { mutate: updateMutate } = useUpdate();
  const { formProps, saveButtonProps, form, redirect } = useForm<ITrainer>();

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState<boolean>(false);

  const [specialityItem, setSpecialityItem] = useState<string>("");
  const [saveSpecialityItem, setSaveSpecialityItem] = useState<string>("");
  const [editSpecialityItem, setEditSpecialityItem] = useState<string>("");
  const [specialityObject, setSpecialityObject] = useState<ISpeciality>();
  const [specialityArr, setSpecialityArr] = useState<string[]>([]);

  const { buttonProps, uploadProps } = useImport();
  const { Option } = Select;
  const { Text } = Typography;

  // const props: UploadProps = {
  //   name: "file",
  // };

  const saveProps = () => {
    const userFormData = new FormData();
    userFormData.set("firstName", form?.getFieldValue("firstName"));
    userFormData.set("lastName", form?.getFieldValue("lastName"));
    userFormData.set("email", form?.getFieldValue("email"));
    userFormData.set("phoneNumber", form?.getFieldValue("phoneNumber"));
    userFormData.set("gender", form?.getFieldValue("gender"));
    // userFormData.set("profilePhoto", form?.getFieldValue("profilePhoto").file);
    userFormData.set("price", form?.getFieldValue("price"));
    userFormData.set("accountStatus", form?.getFieldValue("accountStatus"));
    userFormData.set("isFeatured", form?.getFieldValue("isFeatured"));
    userFormData.set("certificates", certificatesArr as any);

    // const trainerFormData = new FormData();
    // trainerFormData.set("price", form?.getFieldValue("price"));
    // trainerFormData.set("accountStatus", form?.getFieldValue("accountStatus"));
    // trainerFormData.set("isFeatured", form?.getFieldValue("isFeatured"));
    // trainerFormData.set("certificates", certificatesArr as any);
    // trainerFormData.append("user", JSON.stringify(userFormData as any));

    const formData2 = {
      firstName: form?.getFieldValue("firstName"),
      lastName: form?.getFieldValue("lastName"),
      email: form?.getFieldValue("email"),
      phoneNumber: form?.getFieldValue("phoneNumber"),
      gender: form?.getFieldValue("gender"),
      price: form?.getFieldValue("price"),
      accountStatus: form?.getFieldValue("accountStatus"),
      bio: form?.getFieldValue("bio"),
      isFeatured: form?.getFieldValue("isFeatured"),
      certificates: certificatesArr,
      specialities: specialityArr,
      // profilePhoto: form?.getFieldValue("profilePhoto").file,
    } as ITrainer;

    updateMutate(
      {
        resource: "trainer",
        id: formProps?.initialValues?.id,
        values: formData2,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          return redirect("list");
        },
      }
    );
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={saveProps}>
        <Row gutter={[50, 0]}>
          <Col span={10}>
            <Form.Item label="Kullanıcı Adı" name="username">
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Ad"
              name="firstName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Soyad"
              name="lastName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Telefon Numarası"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Hakkımda"
              name="bio"
              rules={[
                {
                  required: true,
                  message: "Açıklama zorunlu!",
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            {/* <Form.Item label="Profil Fotoğrafı" name="profilePhoto">
              <Upload {...uploadProps} accept=".jpg" maxCount={1}>
                <Button icon={<ImportOutlined />} {...buttonProps}>
                  Resim Ekle
                </Button>
              </Upload>
            </Form.Item> */}
          </Col>

          <Col span={8}>
            <Form.Item label="Cinsiyet" name="gender">
              <Select defaultValue={formProps?.initialValues?.gender}>
                <Option value={"male"}>Erkek</Option>
                <Option value={"female"}>Kadın</Option>
                <Option value={"other"}>Diğer</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Deneyim" name="experience">
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Fiyat"
              name="price"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Hesap Durumu" name="accountStatus">
              <Select defaultValue={formProps?.initialValues?.accountStatus}>
                <Option value="approved">Onaylı</Option>
                <Option value="rejected">Onaylı Değil</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Öne Çıkan" name="isFeatured">
              <Select defaultValue={formProps?.initialValues?.isFeatured}>
                <Option value={true}>Evet</Option>
                <Option value={false}>Hayır</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Sertifikalar">
              <CertificatesList
                certificateItem={certificateItem}
                setCertificateItem={setCertificateItem}
                certificatesArr={certificatesArr}
                setCertificatesArr={setCertificatesArr}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <div className="trainer-speciality-edit-box">
              <Form.Item label="Uzmanlık Alanları">
                <SpecialityList
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpenEdit={isModalOpenEdit}
                  setIsModalOpenEdit={setIsModalOpenEdit}
                  specialityItem={specialityItem}
                  setSpecialityItem={setSpecialityItem}
                  saveSpecialityItem={saveSpecialityItem}
                  setSaveSpecialityItem={setSaveSpecialityItem}
                  editSpecialityItem={editSpecialityItem}
                  setEditSpecialityItem={setEditSpecialityItem}
                  specialityObject={specialityObject}
                  setSpecialityObject={setSpecialityObject}
                  specialityArr={specialityArr}
                  setSpecialityArr={setSpecialityArr}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
