import { useShow } from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tag,
  NumberField,
  Rate,
  Button,
  DateField,
  Icons,
  Avatar,
  Row,
  Col,
} from "@pankod/refine-antd";
import { Link, useNavigate } from "react-router-dom";

import "../../style.css";

const { Title, Text } = Typography;

export const PersonalShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const { CalendarOutlined } = Icons;
  const record = data?.data;
  const navigate = useNavigate();

  const goProfile = (data: any) => {
    if (data?.trainer) {
      return navigate(`/trainer/show/${data?.trainer?.id}`);
    }

    if (data?.customer) {
      return navigate(`/customer/show/${data?.customer?.id}`);
    }
  };

  return (
    <Show isLoading={isLoading}>
      <Avatar
        size={64}
        icon={<CalendarOutlined style={{ color: "#626262" }} />}
        style={{
          float: "left",
          marginRight: "20px",
          backgroundColor: "#f5f5f5",
        }}
      />
      <div className="show-item">
        <Title
          type={record?.isCanceled ? "danger" : "success"}
          delete={record?.isCanceled ? true : false}
          level={4}
        >
          {" "}
          Başlama Tarihi : (
          <DateField
            type={record?.isCanceled ? "danger" : "success"}
            format="LLL"
            locales="tr"
            value={record?.startTime}
          />
          ){" "}
        </Title>
      </div>

      <Row>
        <Col span={8}>
          <div className="show-item">
            <Title level={5}>Eğitmen: </Title>
            <Button onClick={() => goProfile({ trainer: record?.trainer })}>
              <Text>
                {record?.trainer?.firstName} {record?.trainer?.lastName}
              </Text>
            </Button>
          </div>

          <div className="show-item">
            <Title level={5}>Müşteri: </Title>
            <Button onClick={() => goProfile({ customer: record?.customer })}>
              <Text>
                {record?.customer?.firstName} {record?.customer?.lastName}
              </Text>
            </Button>
          </div>

          <div className="show-item">
            <Title level={5}>Ücret: </Title>
            <Text>
              {record?.price ? (
                <NumberField
                  value={`${record?.price}`}
                  options={{ style: "currency", currency: "TRY" }}
                />
              ) : (
                "belirtilmemiş"
              )}
            </Text>
          </div>

          <div className="show-item">
            <Title level={5}>Katılımcılar: </Title>
            <Text>{record?.participations.length}</Text>
          </div>
        </Col>

        <Col span={8}>
          <div className="show-item">
            <Title level={5}>Durum: </Title>
            <Tag color={record?.isCanceled ? "red" : "green"}>
              <Text type={record?.isCanceled ? "danger" : "success"}>
                {record?.isCanceled ? "KAPALI" : "AÇIK"}
              </Text>
            </Tag>
          </div>

          <div className="show-item">
            <Title level={5}>İptal Eden Kişi: </Title>
            <Text>
              {record?.isCanceled
                ? record?.canceledBy === "trainer"
                  ? "Eğitmen"
                  : "Müşteri"
                : "iptal edilmemiş"}
            </Text>
          </div>

          <div className="show-item">
            <Title level={5}>İptal Sebebi: </Title>
            <Text>
              {record?.isCanceled
                ? record?.cancellationReason
                : "iptal edilmemiş"}
            </Text>
          </div>
        </Col>

        <Col span={8}>
          <div className="show-item">
            <Title level={5}>Oluşturulma Tarihi:</Title>
            <Text>
              <DateField format="LLL" locales="tr" value={record?.createdAt} />
            </Text>
          </div>

          <div className="show-item">
            <Title level={5}>Başlama Tarihi:</Title>
            <Text>
              <DateField format="LLL" locales="tr" value={record?.startTime} />
            </Text>
          </div>

          <div className="show-item">
            <Title level={5}>Bitiş Tarihi:</Title>
            <Text>
              <DateField format="LLL" locales="tr" value={record?.endTime} />
            </Text>
          </div>
        </Col>
      </Row>
    </Show>
  );
};
