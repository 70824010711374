import React, { useState } from "react";
import { Button, Drawer } from "antd";

import { useShow, useNavigation, useList } from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tag,
  NumberField,
  Rate,
  DateField,
  Icons,
  Avatar,
  Row,
  Col,
  List,
  ShowButton,
} from "@pankod/refine-antd";

import "../../style.css";
import "./style.css";

import { ISessionGroup } from "interfaces/session";

const { Title, Text } = Typography;

export const GroupShow = () => {
  const { queryResult } = useShow<ISessionGroup>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { CalendarOutlined } = Icons;

  const [open, setOpen] = useState(false);

  const { show } = useNavigation();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setCostumerListData([]);
    return;
  };

  const [customerListData, setCostumerListData] = useState<number[]>([]);
  const getPersonList = (type: string) => {
    if (type === "customer") {
      setCostumerListData(record?.customers || []);
    }
    if (type === "participations") {
      setCostumerListData(record?.participations || []);
    }
    return showDrawer();
  };

  return (
    <Show isLoading={isLoading}>
      <Avatar
        size={64}
        icon={<CalendarOutlined style={{ color: "#626262" }} />}
        style={{
          float: "left",
          marginRight: "20px",
          backgroundColor: "#f5f5f5",
        }}
      />
      <div className="show-item">
        <Title
          type={record?.isCanceled ? "danger" : "success"}
          delete={record?.isCanceled ? true : false}
          level={4}
        >
          {" "}
          Başlama Tarihi : (
          <DateField
            type={record?.isCanceled ? "danger" : "success"}
            format="LLL"
            locales="tr"
            value={record?.startTime}
          />
          ){" "}
        </Title>
      </div>

      <Row>
        <Col span={12}>
          <div className="show-item">
            <Title level={5}>Durum: </Title>
            <Tag color={record?.isCanceled ? "red" : "green"}>
              <Text type={record?.isCanceled ? "danger" : "success"}>
                {record?.isCanceled ? "KAPALI" : "AÇIK"}
              </Text>
            </Tag>
          </div>

          <div className="show-item">
            <Title level={5}>Antreman Grubu: </Title>
            <Text>{record?.groupTrainingName}</Text>
          </div>

          <div className="show-item">
            <Title level={5}>Dersi Satın Alanlar: </Title>
            <Tag
              onClick={
                record?.customers.length === 0
                  ? () => {
                      return;
                    }
                  : () => getPersonList("customer")
              }
              className={"item_person-list"}
              color={record?.customers.length === 0 ? "red" : "success"}
            >
              <Text
                type={record?.customers.length === 0 ? "danger" : "success"}
              >
                {record?.customers.length} kişi
              </Text>
            </Tag>
          </div>

          <div className="show-item">
            <Title level={5}>Derse Katılanlar: </Title>
            <Tag
              onClick={
                record?.participations.length === 0
                  ? () => {
                      return;
                    }
                  : () => getPersonList("participations")
              }
              className={"item_person-list"}
              color={record?.participations.length === 0 ? "red" : "success"}
            >
              <Text
                type={
                  record?.participations.length === 0 ? "danger" : "success"
                }
              >
                {record?.participations.length} kişi
              </Text>
            </Tag>
          </div>
        </Col>

        <Col span={12}>
          <div className="show-item">
            <Title level={5}>Oluşturulma Tarihi:</Title>
            <Text>
              <DateField format="LLL" locales="tr" value={record?.createdAt} />
            </Text>
          </div>

          <div className="show-item">
            <Title level={5}>Başlama Tarihi:</Title>
            <Text>
              <DateField format="LLL" locales="tr" value={record?.startTime} />
            </Text>
          </div>

          <div className="show-item">
            <Title level={5}>Bitiş Tarihi:</Title>
            <Text>
              <DateField format="LLL" locales="tr" value={record?.endTime} />
            </Text>
          </div>
        </Col>
      </Row>

      <Drawer
        width={500}
        title="Kişi Listesi"
        placement="right"
        onClose={onClose}
        visible={open}
      >
        <List title={false} breadcrumb>
          {customerListData && customerListData.length > 0 ? (
            customerListData.map((customerId: number) => {
              return (
                <Row
                  className="person-session-item"
                  justify="space-between"
                  align="middle"
                  key={customerId}
                >
                  <Typography.Text>{customerId}</Typography.Text>
                  <ShowButton
                    hideText
                    onClick={() => show("customer", customerId)}
                  />
                </Row>
              );
            })
          ) : (
            <Typography.Text type="secondary" underline>
              Kişi yok!
            </Typography.Text>
          )}
        </List>
      </Drawer>
    </Show>
  );
};
