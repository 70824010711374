import {
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
} from "@pankod/refine-core";

import {
  List,
  Table,
  Avatar,
  useTable,
  DateField,
  Input,
  Icons,
  Form,
  Button,
  FormProps,
  Row,
  Col,
  ShowButton,
  Rate,
} from "@pankod/refine-antd";

import { IUserFilterVariables } from "interfaces";
import { ITrainer } from "interfaces/trainer";

export const TrainerList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, sorter } = useTable<
    ITrainer,
    HttpError,
    IUserFilterVariables
  >({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q } = params;

      filters.push({
        field: "search",
        operator: "eq",
        value: q,
      });
      return filters;
    },
    syncWithLocation: false,
  });

  let listDataSource: any = tableProps?.dataSource
  let listData = listDataSource?.results

  return (
    <Row gutter={[16, 0]} style={{ display: "flex", flexDirection: "column" }}>
      <Col xl={12} lg={24} xs={24} style={{ margin: "0 auto" }}>
        <Filter formProps={searchFormProps} />
      </Col>
      <Col xl={24} xs={24}>
        <List>
          <Table
            {...tableProps}
            dataSource={listData}
            rowKey="id"
          >
            {/* <Table.Column
              align="center"
              key="profilePhoto"
              dataIndex={"profilePhoto"}
              render={(value) => <Avatar src={value[0].url} />}
            /> */}
            <Table.Column
              key="user__first_name"
              dataIndex="firstName"
              title={"Ad"}
              sorter
            />
            <Table.Column key="lastName" dataIndex="lastName" title={"Soyad"}/>
            <Table.Column
              key="experience"
              dataIndex="experience"
              title="Deneyim"
              render={(value) => `+ ${value} yıl`}
              sorter
            />
            <Table.Column
              key="gender"
              dataIndex="gender"
              title={"Cinsiyet"}
              render={(value) =>
                value === "male"
                  ? "Erkek"
                  : value === "female"
                  ? "Kadın"
                  : value === "Other"
                  ? "Diğer"
                  : "Belirtilmemiş"
              }
            />
            <Table.Column
              key="rating"
              dataIndex="rating"
              title="Değerlendirme"
              render={(value) => (
                <Rate disabled allowHalf defaultValue={value} />
              )}
              sorter
            />
            <Table.Column
              key="dateJoined"
              dataIndex="dateJoined"
              title={"Kayıt Tarihi"}
              render={(value) => <DateField value={value} format="LLL" />}
            />
            <Table.Column<ITrainer>
              render={(_, record) => (
                <ShowButton title="Detay" recordItemId={record.id}>
                  Detail
                </ShowButton>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  return (
    <Form layout="vertical" {...props.formProps}>
      <Row
        gutter={[10, 0]}
        style={{ display: "flex", alignItems: "center", gap: "20px" }}
      >
        <Form.Item name="q">
          <Input
            placeholder={"ad, soyad, numara ..."}
            prefix={<Icons.SearchOutlined />}
            style={{ width: "500px" }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: "100%", display: "block" }}
            htmlType="submit"
            type="primary"
          >
            {"Ara"}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
