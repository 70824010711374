import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Create,
  Form,
  Icons,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
  useCheckboxGroup,
  useForm,
  useImport,
  message,
  Space,
} from "@pankod/refine-antd";

import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

import { useCreate, useList } from "@pankod/refine-core";
import { IProduct } from "../../interfaces/product";

export const ProductCreate: React.FC = () => {
  const { mutate } = useCreate();
  const {
    formProps,
    saveButtonProps,
    queryResult,
    form,
    onFinish,
    formLoading,
    mutationResult,
    redirect,
  } = useForm<IProduct>({});
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const saveData = () => {
    // @ts-ignore
    const imagesData = document.querySelectorAll(".ant-upload-list-item-image");
    let imageOne;
    let imageTwo;
    let imageTree;

    imagesData?.forEach((i, index) => {
      if (index === 0) {
        imageOne = i.getAttribute("src");
      }
      if (index === 1) {
        imageTwo = i.getAttribute("src");
      }
      if (index === 2) {
        imageTree = i.getAttribute("src");
      }
    });

    const productData = {
      productName: form?.getFieldValue("productName"),
      description: form?.getFieldValue("description"),
      category: form?.getFieldValue("category"),
      itemCount: form?.getFieldValue("itemCount"),
      price: form?.getFieldValue("price"),
      imageOne,
      imageTwo,
      imageTree,
    };

    mutate(
      {
        resource: "product/create",
        values: productData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          return redirect("list");
        },
      }
    );
  };
  return (
    <div className="training-form-box">
      <Create saveButtonProps={saveButtonProps}>
        <Form {...formProps} onFinish={saveData} layout="vertical">
          <Row gutter={[30, 0]} justify="center">
            <Col xxl={12} xl={14} lg={18} md={24} sm={24}>
              <Form.Item
                name={"productName"}
                label={"Ürün Adı"}
                hasFeedback
                rules={[{ required: true, message: "Ürün Adı Zorunlu !" }]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Ürün adı giriniz..."}
                />
              </Form.Item>

              <Form.Item
                label="Ürün Açıklaması"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Açıklama zorunlu!",
                  },
                ]}
              >
                <ReactMde
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>

              <Form.Item
                name={"category"}
                label={"Kategori"}
                hasFeedback
                rules={[{ required: true, message: "Kategori Adı Zorunlu !" }]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={"Kategori giriniz..."}
                />
              </Form.Item>

              <Form.Item
                name={"price"}
                label={"Ürün Fiyatı"}
                hasFeedback
                rules={[{ required: true, message: "Fiyat Zorunlu !" }]}
              >
                <InputNumber
                  className="product-input"
                  placeholder={"Fiyat..."}
                />
              </Form.Item>

              <Form.Item
                name={"itemCount"}
                label={"Stok Sayısı"}
                hasFeedback
                rules={[{ required: true, message: "Stok Sayısı Zorunlu !" }]}
              >
                <InputNumber
                  className="product-input"
                  placeholder={"Stok sayısı..."}
                />
              </Form.Item>

              <Form.Item name="images">
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size="large"
                >
                  <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture"
                    maxCount={3}
                  >
                    <Button icon={<Icons.UploadOutlined />}>Resim Ekle</Button>
                  </Upload>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Create>
    </div>
  );
};
