import React from 'react'
import {CreateButton, List, Table, TagField, TextField, useTable} from "@pankod/refine-antd";
import {useMany} from "@pankod/refine-core";

export const NotificationList: React.FC = () => {
    const { tableProps } = useTable<any>({
        syncWithLocation: true,
    });


    const resultData: any = tableProps?.dataSource;
    const result = resultData?.results;


    let overrideProps:any = {
        ...tableProps,
        dataSource: result,
    };




    return (
        <List pageHeaderProps={{
            extra : <CreateButton/>
        }}>
            <Table rowKey="id" {...overrideProps}>
                <Table.Column dataIndex="type" title="TYPE"  render={(value:any,record:any) => `${value?.value}`} />
                <Table.Column dataIndex="content" title="Content"  />
            </Table>
        </List>
    )
}
