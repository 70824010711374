import { useState } from "react";

import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  DateField,
  DatePicker,
  Typography
} from "@pankod/refine-antd";
import { ICustomer } from "interfaces/customer";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

const {Option} = Select
const {TextArea} = Input
const { RangePicker } = DatePicker;

export const PersonalEdit: React.FC = () => {
  const { formProps, saveButtonProps } =
    useForm<ICustomer>();
    const sessionData = formProps?.initialValues
    const fullName = sessionData?.trainer?.firstName + " " + sessionData?.trainer?.lastName

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Eğitmen" name={sessionData?.trainer?.firstName}>
          <Input disabled value={sessionData?.trainer?.firstName} placeholder={fullName}/>
        </Form.Item>

        <Form.Item label="Durum" name="isCanceled">
          <Select defaultValue={formProps?.initialValues?.isCanceled}>
            <Option value={true}>Kapalı</Option>
            <Option value={false}>Açık</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Fiyat" name="price">
          <Input placeholder="miktar giriniz..."/>
        </Form.Item>

        <Form.Item label="İptal Eden Kişi" name="canceledBy">
          <Select defaultValue={formProps?.initialValues?.canceledBy}>
            <Option value={'trainer'}>Eğitmen</Option>
            <Option value={'customer'}>Müşteri</Option>
            <Option value={null}>İptal Edilmemiş</Option>
          </Select>
        </Form.Item>

        <Form.Item label="İptal Sebebi" name="cancellationReason">
          <TextArea rows={4} placeholder="iptal edilmiş ise açıklama ekleyiniz..." />
        </Form.Item>

        <Form.Item label="Oluşturulma Tarihi" name="createdAt">
          <DateField format="LLL" locales="tr" value={formProps?.initialValues?.createdAt} /> 
        </Form.Item>

        <Form.Item label="Başlama Tarihi" name="startTime">
        <div className="date-box" style={{display:"flex", alignItems: "center"}}>
          <div className="date-format-box" style={{width: "350px"}}>
          <DateField format="LLL" locales="tr" value={formProps?.initialValues?.startTime}/>
          </div>
          </div>
        </Form.Item>

        <Form.Item label="Bitiş Tarihi" name="endTime">
          <div className="date-box" style={{display:"flex", alignItems: "center"}}>
          <div className="date-format-box" style={{width: "350px"}}>
          <DateField format="LLL" locales="tr" value={formProps?.initialValues?.endTime}/>
          </div>
          </div>
        </Form.Item>
      </Form>
    </Edit>
  );
};
