import { useShow } from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tag,
  NumberField,
  Rate,
  DateField,
  Icons,
  Avatar,
  Row,
  Col,
  MarkdownField,
} from "@pankod/refine-antd";

import { Link, useNavigate } from "react-router-dom";
import { ITraining } from "interfaces/training";

const { Title, Text } = Typography;

export const TrainingShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data as ITraining;

  const navigate = useNavigate();

  const goTrainer = () => {
    if (record?.trainer) {
      return navigate(`/trainer/show/${record?.trainer}`);
    }
  };

  return (
    <div className="training-show-box">
      <Show isLoading={isLoading}>
        <div className="training-show-item">
          <Title
            delete={record?.isActive ? false : true}
            style={record?.isActive ? { opacity: "1" } : { opacity: "0.5" }}
            level={3}
          >
            {record?.title}
          </Title>
          <Text>
            <Tag color={record?.isActive ? "green" : "red"}>
              {" "}
              {record?.isActive ? (
                <Icons.CheckCircleOutlined />
              ) : (
                <Icons.ExclamationCircleOutlined />
              )}{" "}
              {record?.isActive ? "Aktif" : "Kapalı"}
            </Tag>{" "}
            <Tag color={record?.isFeatured ? "green" : "red"}>
              {" "}
              {record?.isFeatured ? (
                <Icons.CheckCircleOutlined />
              ) : (
                <Icons.ExclamationCircleOutlined />
              )}{" "}
              {record?.isFeatured ? "Öne Çıkarılmış" : "Öne Çıkarılmamış"}
            </Tag>
          </Text>
        </div>

        <Row gutter={32}>
          <Col span={12}>
            <div className="left-items">
              <div className="training-show-item">
                <Title level={5}>Eğitmen </Title>
                <div className="show-item-text">
                  <Text className="trainer-click" onClick={goTrainer}>
                    {record?.trainerName}{" "}
                    <Icons.RightOutlined className="trainer-click-arrow" />{" "}
                  </Text>
                </div>
              </div>

              <div className="training-show-item">
                <Title level={5}>Kategori </Title>
                <div className="show-item-text">
                  <Text>{record?.categoryName}</Text>
                </div>
              </div>

              <div className="training-show-item">
                <Title level={5}>Ders Hakkında</Title>
                <div className="show-item-text training-description">
                  {/* <Text>{record?.description}</Text> */}
                  <MarkdownField value={record?.description} />
                </div>
              </div>
            </div>
          </Col>

          <Col span={12}>
            <div className="right-items">
              <div className="training-show-item">
                <Title level={5}>Zorluk Derecesi </Title>
                <div className="show-item-text">
                  {record?.difficulty === 0 ? (
                    <Text>Başlangıç</Text>
                  ) : record?.difficulty === 1 ? (
                    <Text>Kolay</Text>
                  ) : record?.difficulty === 2 ? (
                    <Text>Orta</Text>
                  ) : record?.difficulty === 3 ? (
                    <Text>Zor</Text>
                  ) : record?.difficulty === 4 ? (
                    <Text>Uzman</Text>
                  ) : (
                    <Text>Belirtilmemiş</Text>
                  )}
                </div>
              </div>

              <div className="training-show-item">
                <Title level={5}>Kota</Title>
                <div className="show-item-text">
                  <Text>{record?.quota} Kişilik</Text>
                </div>
              </div>

              <div className="training-show-item">
                <Title level={5}>Favorilenme Sayısı</Title>
                <div className="show-item-text">
                  <Text>{record?.favoritedBy.length}</Text>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Show>
    </div>
  );
};
