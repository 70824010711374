import {Create, Form, Input, Select, useForm, useSelect} from "@pankod/refine-antd";
import React from "react";
import {useCreate} from "@pankod/refine-core";

export const NotificationCreate:React.FC= () => {
    const { formProps, saveButtonProps,form ,redirect} = useForm<any>();

    const {mutate} = useCreate()


    const { selectProps: categorySelectProps ,queryResult} = useSelect<any>({
        resource: "notification-types",
    });


    const categoryData = queryResult?.data?.data



    const onFormPress= () => {
        let formValue= {
            type : form?.getFieldValue('type'),
            content : form?.getFieldValue('content')
        }


        mutate(
            {
                resource: "notifications/create",
                values: formValue,
            },
            {
                onError: (error, variables, context) => {
                    return error.message;
                },
                onSuccess: (data, variables, context) => {
                    return redirect("list");
                },
            }
        );
    }



    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical"  onFinish={onFormPress}>
                <Form.Item
                    label="type"
                    name={"type"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select  placeholder={'Bildirim Türü Seç'}  >
                        {categoryData && categoryData.map((i: any) => {
                            // @ts-ignore
                            return <Select.Option key={i.name} value={i.name}>{i.value}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label={'İçerik'} name={'content'}>
                    <Input.TextArea rows={4}  />
                </Form.Item>
            </Form>
        </Create>
    )
}
