import { IResourceComponentsProps, HttpError } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  Row,
  Col,
  ShowButton,
  NumberField,
  EditButton,
  DateField,
  Tag,
} from "@pankod/refine-antd";

import { IUserFilterVariables } from "interfaces";
import { IOrder } from "interfaces/order";

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, sorter } = useTable<
    IOrder,
    HttpError,
    IUserFilterVariables
  >({
    resource: "order/",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    syncWithLocation: false,
  });

  let listData: any = tableProps?.dataSource;

  const getOrderStatus = (status: string) => {
    switch (status) {
      case "PENDING":
        return "Beklemede";
        break;

      case "SHIPPED":
        return "Kargoya Verildi";
        break;

      case "DELIVERED":
        return "Teslim Edilmiş";
        break;

      case "CANCELLED":
        return "İptal Edilmiş";
        break;

      case "PREPARING":
        return "Hazırlanıyor";
        break;

      default:
        return "Beklemede";
        break;
    }
  };

  return (
    <Row gutter={[16, 0]} style={{ display: "flex", flexDirection: "column" }}>
      <Col xl={24} xs={24}>
        <List>
          <Table {...tableProps} dataSource={listData} rowKey="id">
            <Table.Column
              key="id"
              dataIndex="orderStatus"
              title={"Sipariş Durumu"}
              render={(value) => (
                <Tag
                  color={
                    value === "CANCELLED"
                      ? "red"
                      : value === "DELIVERED"
                      ? "success"
                      : value === "SHIPPED"
                      ? "blue"
                      : value === "PREPARING"
                      ? "warning"
                      : value === "PENDING"
                      ? "default"
                      : "default"
                  }
                >
                  {getOrderStatus(value)}
                </Tag>
              )}
            />
            <Table.Column
              key="orderCargoNumber"
              dataIndex="orderCargoNumber"
              title="Kargo No"
            />
            <Table.Column
              key="orderItems"
              dataIndex="orderItems"
              title="Ürün Adet"
              render={(value) => value && `${value.length} ürün`}
            />
            <Table.Column
              dataIndex="orderDate"
              title="Sipariş Tarihi"
              render={(value) => (
                <DateField format="LLL" locales="tr" value={value} />
              )}
            />
            <Table.Column key="orderCity" dataIndex="orderCity" title="Şehir" />
            <Table.Column
              key="orderTotal"
              dataIndex="orderTotal"
              title={"Total"}
              render={(value) =>
                value && (
                  <NumberField
                    value={value}
                    options={{ style: "currency", currency: "TRY" }}
                  />
                )
              }
            />
            <Table.Column<IOrder>
              render={(_, record) => (
                <>
                  <ShowButton hideText title="Detay" recordItemId={record.id}>
                    Detail
                  </ShowButton>{" "}
                  <EditButton
                    hideText
                    title="Detay"
                    recordItemId={record.id}
                  ></EditButton>
                </>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
