import {
  List,
  DateField,
  Table,
  useTable,
  ShowButton,
  EditButton,
  Space,
  NumberField,
  DeleteButton,
  getDefaultSortOrder,
  Tag,
  CreateButton, FilterDropdown, Select,
} from "@pankod/refine-antd";

import { ICustomer } from "interfaces/customer";
import React from "react";


export const PersonalList: React.FC = () => {
  const [sortValue,setSortValue] = React.useState<any>()
  const { tableProps, sorter } = useTable<ICustomer>({
    initialPageSize: 10,
    initialSorter: [
      {
        field: "startTime",
        order: "asc",
      },
    ],
  });

  let resultData: any = tableProps?.dataSource;
  let result = resultData?.results;

  //sort by createdAt date
  let ascSortedResult = result?.sort((a: any, b: any) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

  let descSortedResult = result?.sort((a: any, b: any) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  let total = resultData?.count;
  let paginationData: any = tableProps?.pagination;
  let overrideProps = {
    ...tableProps,
    dataSource: sortValue === 1 ? ascSortedResult : descSortedResult ,
    total,
    page: paginationData?.current,
  };

  return (
    <>
      <List>
        <Table {...overrideProps} dataSource={result} rowKey="id"  >
          <Table.Column
            dataIndex="isCanceled"
            title="Durum"
            render={(value) => (
              <Tag color={value === true ? "error" : "success"}>
                {value === true ? "KAPALI" : "AÇIK"}
              </Tag>
            )}
            defaultSortOrder={getDefaultSortOrder("email", sorter)}
          />
          <Table.Column
            dataIndex="startTime"
            title="Başlama Tarihi"
            render={(value) => (
              <DateField format="LLL" locales="tr" value={value} />
            )}
            sortDirections={['ascend', 'descend',]}
            sorter={{
              compare: (a: any, b: any) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
            }}
            sortOrder={'ascend'}
            showSorterTooltip={true}
            defaultSortOrder={getDefaultSortOrder("startTime", sorter )}
          />
          <Table.Column
            dataIndex="endTime"
            title="Bitiş Tarihi"
            render={(value) => (
              <DateField format="LLL" locales="tr" value={value} />
            )}
            defaultSortOrder={getDefaultSortOrder("firstName", sorter)}
          />
          <Table.Column
            dataIndex="trainer"
            title="Eğitmen"
            render={(value) => `${value?.firstName} ${value?.lastName}`}
            defaultSortOrder={getDefaultSortOrder("email", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="customer"
            title="Müşteri"
            render={(value) => `${value?.firstName} ${value?.lastName}`}
            defaultSortOrder={getDefaultSortOrder("email", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="price"
            title="Fiyat"
            render={(value) =>
              value && (
                <NumberField
                  value={value}
                  options={{ style: "currency", currency: "TRY" }}
                />
              )
            }
            defaultSortOrder={getDefaultSortOrder("price", sorter)}
            sorter
          />
          <Table.Column<ICustomer>
            title="Seçenekler"
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton size="middle" recordItemId={record.id} hideText />
                  <EditButton size="middle" recordItemId={record.id} hideText />
                  <DeleteButton
                    size="middle"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
