import { Avatar, Divider, List, Skeleton, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const UserList = ({customerData}: any) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const goProfile = (userId: Number) => {
    navigate(`/customer/show/${userId}`)
  }
  
  return (
   <>
    <div
      id="scrollableDiv"
      style={{
        height:"456px",
        overflow: 'auto',
        padding: '20px',
        boxShadow: '0 5px 5px rgba(0, 0, 0, 0.1)',
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        marginTop: "7px",
        marginLeft: "8px"
      }}
    >
      <h1>Son kayıt olan 5 kişi</h1>
      <List
          dataSource={customerData?.data?.results}
          renderItem={(item: any) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                // avatar={<Avatar src={`https://xsgames.co/randomusers/assets/avatars/male/${item.id > 75 ? item.id % 10 : item.id}.jpg`} />}
                title={<a href="https://ant.design">{item.firstName + ' ' + item.lastName}</a>}
                description={item.email}
              />
              <Tag color={'magenta'} style={{cursor: "pointer"}} onClick={() => goProfile(item.id)} className='tag tag-2x'>Profile Git</Tag>
            </List.Item>
          )}
        />
    </div>
   </>
  );
};

export default UserList;