import refineSimpleRestDataProvider from "@pankod/refine-simple-rest";

const API_URL = "http://localhost:8000/api/admin";

const dataProvider = refineSimpleRestDataProvider(API_URL);

// @ts-ignore: Unreachable code error
export const auditLogProvider: AuditLogProvider = {
  // @ts-ignore: Unreachable code error
  get: async ({ resource, meta }) => {
    // @ts-ignore: Unreachable code error
    const { data } = await dataProvider(API_URL).getList({
      resource: "logs",
      filters: [
        {
          field: "resource",
          operator: "eq",
          value: resource,
        },
        {
          field: "meta.id",
          operator: "eq",
          value: meta?.id,
        },
      ],
    });

    return data;
  },
  create: (params: any) => {
    // @ts-ignore: Unreachable code error
    return dataProvider(API_URL).create({
      resource: "logs",
      variables: params,
    });
  },
  // @ts-ignore: Unreachable code error
  update: async ({ id, name }) => {
    // @ts-ignore: Unreachable code error
    const { data } = await dataProvider(API_URL).update({
      resource: "logs",
      id,
      variables: { name },
    });
    return data;
  },
};
