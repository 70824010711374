import { useEffect } from "react";

import {
  useForm,
  Form,
  Input,
  Select,
  Button,
  Icons,
  Tag,
  Row,
  Col,
  Modal,
  Popconfirm,
} from "@pankod/refine-antd";

import { useUpdate, useList, useCreate, useDelete } from "@pankod/refine-core";

import { ISpeciality, ITrainer } from "interfaces/trainer";

type SpecialityProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  isModalOpenEdit: boolean;
  setIsModalOpenEdit: (value: boolean) => void;
  specialityItem: string;
  setSpecialityItem: (value: string) => void;
  saveSpecialityItem: string;
  setSaveSpecialityItem: (value: string) => void;
  editSpecialityItem: string;
  setEditSpecialityItem: (value: string) => void;
  specialityObject: ISpeciality | undefined;
  setSpecialityObject: (value: ISpeciality) => void;
  specialityArr: string[];
  setSpecialityArr: (value: string[]) => void;
};

export const SpecialityList: React.FC<SpecialityProps> = ({
  editSpecialityItem,
  isModalOpen,
  isModalOpenEdit,
  saveSpecialityItem,
  setEditSpecialityItem,
  setIsModalOpen,
  setIsModalOpenEdit,
  setSaveSpecialityItem,
  setSpecialityArr,
  setSpecialityItem,
  setSpecialityObject,
  specialityArr,
  specialityItem,
  specialityObject,
}) => {
  const { mutate: updateMutate } = useUpdate();
  const { mutate: createMutate } = useCreate();
  const { mutate: deleteMutate } = useDelete();
  const { formProps } = useForm<ITrainer>();

  const { Option } = Select;

  // Speciality create
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Speciality edit
  const showModalEdit = () => {
    setEditSpecialityItem(specialityItem);
    const objectItem = specialityData?.find((i: ISpeciality) => {
      return i.name === specialityItem;
    });
    if (objectItem) {
      setSpecialityObject(objectItem);
    }
    setIsModalOpenEdit(true);
  };

  const handleOkEdit = () => {
    setIsModalOpenEdit(false);
  };

  const handleCancelEdit = () => {
    setIsModalOpenEdit(false);
  };

  // SPECIALIYT EDIT START
  const { refetch: createRefetch, data: specialityDataResult } =
    useList<ISpeciality>({
      resource: "trainer/speciality/",
      config: {
        pagination: { current: 1, pageSize: 1000 },
        hasPagination: true,
      },
    });

  // @ts-ignore
  const specialityData = specialityDataResult?.data?.results;

  const specialityList = formProps?.initialValues?.specialities;

  const addSpeciality = (specialityName: string) => {
    if (!specialityName.length) {
      return;
    }

    const specialityCheck = specialityArr.find((i: string) => {
      return i === specialityName;
    });

    if (specialityCheck) {
      return;
    }

    setSpecialityArr([...specialityArr, specialityItem]);
  };

  const delSpeciality = (specialityName: string) => {
    if (!specialityArr.length) {
      return;
    }
    const newSpecialityList = specialityArr.filter((i: string) => {
      return i !== specialityName;
    });
    setSpecialityArr(newSpecialityList);
  };

  const getSpecialityList = () => {
    const specialityListResult = formProps?.initialValues?.specialities;
    if (specialityListResult) {
      setSpecialityArr(specialityListResult);
    }
  };

  const saveSpeciality = (specialityName: string) => {
    const specialityFormData = new FormData();
    specialityFormData.append("name", specialityName);

    createMutate(
      {
        resource: "trainer/speciality",
        values: specialityFormData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          setSaveSpecialityItem("");
          return createRefetch();
        },
      }
    );
  };

  const updateSpeciality = () => {
    const updateSpecialityFormData = new FormData();
    updateSpecialityFormData.append("name", editSpecialityItem);

    updateMutate(
      {
        resource: "trainer/speciality",
        values: updateSpecialityFormData,
        id: specialityObject?.id || "",
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      }
    );
  };

  const confirmDelete = () => {
    deleteMutate(
      {
        resource: "trainer/speciality",
        id: specialityObject?.id || "",
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      }
    );
  };

  const cancelDelete = () => {
    return;
  };

  // SPECIALIYT EDIT END
  useEffect(() => {
    getSpecialityList();
  }, [specialityList, createMutate, updateMutate]);

  return (
    <div className="speciality-list-box">
      <Row justify="space-between">
        <Col span={19}>
          <Select
            placeholder="Uzmanlık Seç"
            onChange={(e) => setSpecialityItem(e)}
          >
            {specialityData?.map((i: ISpeciality) => {
              return (
                <Option key={i.id} value={i.name}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={2}>
          <Button
            shape="circle"
            icon={<Icons.PlusOutlined />}
            onClick={showModal}
          />
          <Modal
            title="Yeni Uzmanlık Alanı Oluştur"
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Kapat
              </Button>,
            ]}
          >
            <Form layout="vertical">
              <Form.Item
                name="name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Uzmanlık adı zorunlu!",
                  },
                ]}
              >
                <Input
                  placeholder="Uzmanlık Alanı"
                  onChange={(e) => setSaveSpecialityItem(e.target.value)}
                  minLength={1}
                />
              </Form.Item>
              <Button
                type="primary"
                style={{ display: "block", margin: "0 auto" }}
                onClick={() => saveSpeciality(saveSpecialityItem)}
              >
                Oluştur
              </Button>
            </Form>
          </Modal>
        </Col>

        <Col span={2}>
          <Button
            shape="circle"
            icon={<Icons.EditOutlined />}
            onClick={() => showModalEdit()}
            disabled={specialityItem.length ? false : true}
          />
          <Modal
            title="Uzmanlık Adı Düzenle"
            visible={isModalOpenEdit}
            onOk={handleOkEdit}
            onCancel={handleCancelEdit}
            footer={[
              <Button key="back" onClick={handleCancelEdit}>
                Kapat
              </Button>,
            ]}
          >
            <Form layout="vertical">
              <Form.Item
                name={specialityItem}
                initialValue={specialityItem}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Uzmanlık adı zorunlu!",
                  },
                ]}
              >
                <Input
                  placeholder="Uzmanlık Alanı"
                  onChange={(e) => setEditSpecialityItem(e.target.value)}
                />
              </Form.Item>
              <div
                className="speciality-update-buttons"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Button type="primary" onClick={() => updateSpeciality()}>
                  Güncelle
                </Button>

                <Popconfirm
                  title="Are you sure to delete this speciality?"
                  onConfirm={() => confirmDelete()}
                  onCancel={() => cancelDelete()}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="primary"
                    danger
                    icon={<Icons.DeleteOutlined />}
                  />
                </Popconfirm>
              </div>
            </Form>
          </Modal>
        </Col>
      </Row>

      <div className="speciality-box">
        <Button
          type="primary"
          danger
          style={{ margin: "20px auto" }}
          className="trainer-edit-add-button"
          size="small"
          onClick={() => addSpeciality(specialityItem)}
        >
          Uzmanlık Ekle
        </Button>

        <div className="certificates-list">
          {specialityArr && specialityArr.length > 0 ? (
            specialityArr.map((i: string, index) => {
              return (
                <Tag
                  onDoubleClick={() => delSpeciality(i)}
                  color="magenta"
                  className="tag tag-2x certificate-tag-box"
                  style={{ display: "block", margin: "15px 0" }}
                  key={index}
                >
                  <div className="certificate-tag">
                    <span className="tag-certificate">{i}</span>
                    <span className="tag-delete">Silmek için çift tıkla!</span>
                  </div>
                </Tag>
              );
            })
          ) : (
            <span className="certificates-message certificates-message-center">
              Eklenmiş bir uzmanlık yok!
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
