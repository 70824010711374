import axios, { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { dataProvider } from "../interfaces/dataProvider";
import { toast } from "react-toastify";

const axiosInstance = axios.create();

const generateSort = (sort?: any) => {
  let _sort = ["id"]; // default sorting field
  let _order = ["desc"]; // default sorting
  let ordering = "";

  if (sort) {
    _sort = [];
    _order = [];

    sort.map((item: any) => {
      _sort.push(item.field);
      _order.push(item.order);
      if (item.order === "desc") {
        ordering = `-${item.field}`;
      } else if (item.order === "asc") {
        ordering = `${item.field}`;
      } else {
        ordering = "";
      }
    });
  }

  return {
    ordering,
  };
};

const mapOperator = (operator: any): string => {
  switch (operator) {
    case "ne":
    case "gte":
    case "lte":
      return `_${operator}`;
    case "contains":
      return "_like";
  }

  return ""; // default "eq"
};

const generateFilter = (filters?: any) => {
  const queryFilters: { [key: string]: string } = {};
  if (filters) {
    // @ts-ignore: Unreachable code error
    filters.map(({ field, operator, value }) => {
      const mappedOperator = mapOperator(operator);
      queryFilters[`${field}${mappedOperator}`] = value;
    });
  }
  return queryFilters;
};

export const SimpleRestDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): dataProvider => ({
  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}/`;

    const localData = JSON.parse(localStorage.getItem("monaxe") as any);
    const config = {
      headers: {
        Authorization: `Token ${localData.token}`,
        'Content-Type': 'application/json'
      },
    };

    const { data } = await httpClient.post(url, variables, config).then((res) => {
      res.status = 200;
      toast.success("Ekleme başarılı");
      return res;
    })
    .catch((err) => {
      if(err.response.data.error === "name - This field may not be blank.") {
      toast.error("Bu alan boş bırakılamaz!");
      throw new Error(err);
      }

      if(err.response.data.error === "name - speciality with this name already exists.") {
        toast.error("Bu isim zaten kayıtlı!");
        throw new Error(err);
      }

      toast.error(err.response.data.error);
      throw new Error(err);
    });

    return {
        data,
    };
},

  createMany: ({ resource, variables, metaData }) => Promise,

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}/`;

    const localData = JSON.parse(localStorage.getItem("monaxe") as any);
    const config = {
      headers: {
        Authorization: `Token ${localData.token}`,
      },
    };

    const { data } = await httpClient
      .delete(url, config)
      .then((res) => {
        res.status = 200;
        toast.success("Silme başarılı");
        return res;
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        throw new Error(err);
      });

    return {
      data,
    };
  },

  deleteMany: ({ resource, ids, variables, metaData }) => Promise,

  getList: async ({ resource, hasPagination, pagination, filters, sort }) => {
    let url = `${apiUrl}/${resource}`;

    const page = pagination?.current || 1;
    const page_size = pagination?.pageSize || 10;

    const { ordering } = generateSort(sort);
    const queryFilters = generateFilter(filters);

    let resourceQuery = resource.split("?")
    let newQuery
    let queryValue
    if(resourceQuery.length > 1) {
      url = `${apiUrl}/${resourceQuery[0]}`
      newQuery = resourceQuery[1].split("=")
      queryValue = newQuery[1]
    }

    let query = {
      ...(hasPagination
        ? {
            page,
            page_size,
          }
        : {}),
      ...(ordering.length && {
        ordering,
      }),
      ...(queryFilters?.search && {
        search: queryFilters.search,
      }),
      ...(resourceQuery.length > 1 && {
        username: queryValue,
      })
    };

    const localData = JSON.parse(localStorage.getItem("monaxe") as any);
    const headerConfig = {
      headers: {
        Authorization: `Token ${localData.token}`,
      },
    };

    const { data } = await httpClient.get(`${url}?${stringify(query)}`, headerConfig);

    const total = data?.count;

    return {
      data,
      total,
      page,
    };
  },

  getMany: ({ resource, ids, metaData }) => Promise,

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const localData = JSON.parse(localStorage.getItem("monaxe") as any);
    const config = {
      headers: {
        Authorization: `Token ${localData.token}`,
      },
    };

    const { data } = await httpClient.get(url, config);

    return {
      data,
    };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}/`;

    const localData = JSON.parse(localStorage.getItem("monaxe") as any);
    const config = {
      headers: {
        Authorization: `Token ${localData.token}`,
      },
    };

    const data = await httpClient
      .patch(url, variables, config)
      .then((res) => {
        res.status = 200;
        toast.success("Güncelleme başarılı");
        return res;
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        throw new Error(err);
      });

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables, metaData }) => Promise,

  custom: async ({ url, method, filters, sort, payload, query, headers }) => {

    let requestUrl = `${apiUrl}/${url}`;

    if (sort) {
      const { ordering } = generateSort(sort);
      const queryFilters = generateFilter(filters);
        requestUrl = `${requestUrl}&${stringify(ordering as any)}`;
    }

    if (filters) {
        const filterQuery = generateFilter(filters);
        requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
        requestUrl = `${requestUrl}?${stringify(query)}`;
    }

    const localData = JSON.parse(localStorage.getItem("monaxe") as any);


        httpClient.defaults.headers = {
            ...httpClient.defaults.headers,
            ...headers,
            Authorization: `Token ${localData.token}`,
        };



    let axiosResponse;
    switch (method) {
        case "put":
        case "post":
        case "patch":
          // @ts-ignore
            axiosResponse = await httpClient[method](url, payload);
            break;
        case "delete":
            axiosResponse = await httpClient.delete(url, {
                data: payload,
            });
            break;
        default:
            axiosResponse = await httpClient.get(requestUrl);
            break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
},
  getApiUrl: () => "",

});
