import React from "react";
import { TitleProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <div
    className="logo-box"
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Link to="/">
      {collapsed ? (
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px 24px",
            fontSize: "30px",
            color: "#ffffff",
            fontWeight: 600,
            fontStyle: "italic",
          }}
        >
          m
        </h1>
      ) : (
        <img
          src={"/img/lets-motion.png"}
          alt="Refine"
          style={{
            width: "120px",
          }}
        />
      )}
    </Link>
  </div>
);
