import { useState, useEffect } from "react";

import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  Typography,
  useImport,
  Button,
  Icons,
  Upload,
  Row,
  Col,
  InputNumber,
} from "@pankod/refine-antd";

import { useUpdate } from "@pankod/refine-core";

import type { UploadProps } from "@pankod/refine-antd";

import { IProduct } from "interfaces/product";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

const { ImportOutlined } = Icons;

export const ProductEdit: React.FC = () => {
  const { mutate: updateMutate } = useUpdate();
  const { formProps, saveButtonProps, form, redirect } = useForm<IProduct>();

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const { buttonProps, uploadProps } = useImport();
  const { Option } = Select;
  const { Text } = Typography;

  // @ts-ignore
  let imageOneData = formProps?.initialValues?.imageOne;

  // @ts-ignore
  let imageTwoData = formProps?.initialValues?.imageTwo;

  // @ts-ignore
  let imageTreeData = formProps?.initialValues?.imageTree;

  useEffect(() => {}, []);

  const saveProps = () => {
    let imageOne = document
      .querySelector("#imageOne img.ant-upload-list-item-image")
      ?.getAttribute("src");
    let imageTwo = document
      .querySelector("#imageTwo img.ant-upload-list-item-image")
      ?.getAttribute("src");
    let imageTree = document
      .querySelector("#imageTree img.ant-upload-list-item-image")
      ?.getAttribute("src");

    const productData = {
      productName: form?.getFieldValue("productName"),
      description: form?.getFieldValue("description"),
      category: form?.getFieldValue("category"),
      itemCount: form?.getFieldValue("itemCount"),
      price: form?.getFieldValue("price"),
      imageOne: imageOne,
      imageTwo: imageTwo,
      imageTree: imageTree,
    };

    updateMutate(
      {
        resource: "product",
        id: formProps?.initialValues?.id,
        values: productData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          return redirect("list");
        },
      }
    );
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={saveProps}>
        <Row gutter={[50, 0]} justify={"center"}>
          <Col span={12}>
            <Form.Item
              label="Ürün Adı"
              name="productName"
              rules={[
                {
                  required: true,
                  message: "Ürün adı zorunlu!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Ürün Açıklaması"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Ürün açıklaması zorunlu!",
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            <Form.Item
              label="Fiyat"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Ürün fiyatı zorunlu!",
                },
              ]}
            >
              <InputNumber className="product-input" />
            </Form.Item>

            <Form.Item
              label="Stok Sayısı"
              name="itemCount"
              rules={[
                {
                  required: true,
                  message: "Stok sayısı zorunlu!",
                },
              ]}
            >
              <InputNumber className="product-input" />
            </Form.Item>

            <div id="imageOne">
              <Form.Item>
                {imageOneData && (
                  <img className="product-image" src={imageOneData} />
                )}
                <Upload
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  listType="picture"
                  maxCount={1}
                >
                  <Button icon={<Icons.UploadOutlined />}>
                    {imageOneData ? "Güncelle" : "Resim Ekle"}
                  </Button>
                </Upload>
              </Form.Item>
            </div>

            <div id="imageTwo">
              <Form.Item name="imageTwo">
                {imageTwoData && (
                  <img className="product-image" src={imageTwoData} />
                )}
                <Upload
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  listType="picture"
                  maxCount={1}
                >
                  <Button icon={<Icons.UploadOutlined />}>
                    {imageTwoData ? "Güncelle" : "Resim Ekle"}
                  </Button>
                </Upload>
              </Form.Item>
            </div>

            <div id="imageTree">
              <Form.Item name="imageTree">
                {imageTreeData && (
                  <img className="product-image" src={imageTreeData} />
                )}
                <Upload
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  listType="picture"
                  maxCount={1}
                >
                  <Button icon={<Icons.UploadOutlined />}>
                    {imageTreeData ? "Güncelle" : "Resim Ekle"}
                  </Button>
                </Upload>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
