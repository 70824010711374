import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Create,
  Form,
  Icons,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
  useCheckboxGroup,
  useForm,
  useImport,
} from "@pankod/refine-antd";

import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";

import { useCreate, useList } from "@pankod/refine-core";
import { ITraining } from "../../interfaces/training";
import { ITrainer } from "../../interfaces/trainer";
import { ISpeciality } from "interfaces/trainer";
import { IUser } from "../../interfaces/user";

import { CertificatesList } from "../../components/trainer/certificates";
import { SpecialityList } from "../../components/trainer/speciality";

export const TrainerCreate: React.FC = () => {
  const [certificateItem, setCertificateItem] = useState<string>("");
  const [certificatesArr, setCertificatesArr] = useState<string[]>([]);

  const [specialityItem, setSpecialityItem] = useState<string>("");
  const [saveSpecialityItem, setSaveSpecialityItem] = useState<string>("");
  const [editSpecialityItem, setEditSpecialityItem] = useState<string>("");
  const [specialityObject, setSpecialityObject] = useState<ISpeciality>();
  const [specialityArr, setSpecialityArr] = useState<string[]>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const { Option } = Select;

  const userDataResult = useList<IUser>({
    resource: "users",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });

  const trainerDataResult = useList<ITrainer>({
    resource: "trainer",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });

  const specialityDataResult = useList<any>({
    resource: "trainer-specialities",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });

  //@ts-ignore
  const specialityData = specialityDataResult?.data?.data?.results;

  //@ts-ignore
  const userData = userDataResult?.data?.data?.results;

  //@ts-ignore
  const trainerUsernameData = trainerDataResult?.data?.data?.results.map(
    (i: IUser) => i.username
  );

  const newTrainerList = userData?.filter((user: IUser) => {
    if (!trainerUsernameData?.includes(user.username)) {
      return user;
    }
  });

  const { mutate } = useCreate();
  const {
    formProps,
    saveButtonProps,
    queryResult,
    form,
    onFinish,
    formLoading,
    mutationResult,
    redirect,
  } = useForm<ITrainer>({});

  const { buttonProps, uploadProps } = useImport();

  const saveData = () => {
    let formUser = userData.find(
      (item: any) => item.username === form?.getFieldValue("user")
    );

    let userForm = {
      user: {
        firstName: formUser.firstName,
        lastName: formUser.lastName,
        gender: formUser.gender,
        email: formUser.email,
        phoneNumber: formUser.phoneNumber,
        lastLogin: formUser.lastLogin,
        dateJoined: formUser.dateJoined,
        lastActivity: formUser.lastActivity,
        isActive: formUser.isActive,
      },
    };

    let newTrainerData = {
      user: userForm.user,
      username: formUser.username,
      specialities: [],
      bio: form?.getFieldValue("bio"),
      certificates: ["IFBB"],
      experience: form?.getFieldValue("experience"),
      isFeatured: form?.getFieldValue("isFeatured"),
      price: form?.getFieldValue("price"),
      rating: form?.getFieldValue("rating"),
      accountStatus: "review",
      phone_number: formUser.phoneNumber,
    };

    mutate(
      {
        resource: "trainer/create",
        values: newTrainerData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          return redirect("list");
        },
      }
    );
  };
  return (
    <div className="training-form-box">
      <Create saveButtonProps={saveButtonProps}>
        <Form {...formProps} onFinish={saveData} layout="vertical">
          <Row gutter={[30, 0]} justify="center">
            <Col xxl={12} xl={14} lg={18} md={24} sm={24}>
              <Form.Item
                name={"user"}
                label={"Kullanıcı"}
                hasFeedback
                rules={[{ required: true, message: "Kullanıcı Zorunlu !" }]}
              >
                <Select placeholder={"Kullanıcı Seç"}>
                  {newTrainerList &&
                    newTrainerList.map((item: IUser) => {
                      if (item.firstName && item.lastName) {
                        return (
                          <Select.Option
                            key={item?.username}
                            value={item?.username}
                          >
                            {item.firstName + " " + item.lastName}
                          </Select.Option>
                        );
                      }
                    })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Hakkımda"
                name="bio"
                rules={[
                  {
                    required: true,
                    message: "Açıklama zorunlu!",
                  },
                ]}
              >
                <ReactMde
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>

              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <Form.Item name={"experience"} label={"Deneyim"}>
                    <Input
                      placeholder={"Tecrübe edilen yıl sayısı..."}
                      type={"number"}
                      max={100}
                      min={0}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name={"price"} label={"Ücret"}>
                    <Input
                      placeholder={"İstenilen ücret"}
                      type={"number"}
                      min={10}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <Form.Item label="Öne Çıkan" name="isFeatured">
                    <Select
                      defaultValue={
                        formProps?.initialValues?.isFeatured
                          ? formProps?.initialValues?.isFeatured
                          : false
                      }
                    >
                      <Option value={true}>Evet</Option>
                      <Option value={false}>Hayır</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Hesap Durumu" name="accountStatus">
                    <Select
                      defaultValue={
                        formProps?.initialValues?.accountStatus
                          ? formProps?.initialValues?.accountStatus
                          : "review"
                      }
                    >
                      <Option value="review">İncelemede</Option>
                      <Option value="approved">Onaylı</Option>
                      <Option value="rejected">Onaylı Değil</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="profile_photo">
                <Upload
                  name={"profile_photo"}
                  {...uploadProps}
                  accept=".jpg"
                  maxCount={1}
                >
                  <Button icon={<Icons.UploadOutlined />} {...buttonProps}>
                    Resim Ekle
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col xxl={12} xl={10} lg={18} md={24} sm={24}>
              <Row gutter={[30, 0]}>
                <Col xxl={12} xl={24} lg={24} md={24} sm={24}>
                  <Form.Item label="Sertifikalar">
                    <CertificatesList
                      certificateItem={certificateItem}
                      setCertificateItem={setCertificateItem}
                      certificatesArr={certificatesArr}
                      setCertificatesArr={setCertificatesArr}
                    />
                  </Form.Item>
                </Col>
                <Col xxl={12} xl={24} lg={24} md={24} sm={24}>
                  <div className="trainer-speciality-edit-box">
                    <Form.Item label="Uzmanlık Alanları">
                      <SpecialityList
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        isModalOpenEdit={isModalOpenEdit}
                        setIsModalOpenEdit={setIsModalOpenEdit}
                        specialityItem={specialityItem}
                        setSpecialityItem={setSpecialityItem}
                        saveSpecialityItem={saveSpecialityItem}
                        setSaveSpecialityItem={setSaveSpecialityItem}
                        editSpecialityItem={editSpecialityItem}
                        setEditSpecialityItem={setEditSpecialityItem}
                        specialityObject={specialityObject}
                        setSpecialityObject={setSpecialityObject}
                        specialityArr={specialityArr}
                        setSpecialityArr={setSpecialityArr}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Create>
    </div>
  );
};
