import { useState } from "react";

import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  Row,
  Col,
  InputNumber,
  useImport,
  Upload,
  Button,
  Icons,
} from "@pankod/refine-antd";
import type { UploadProps } from "@pankod/refine-antd";
import { ITraining, ICategory, ISubCategory } from "interfaces/training";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import { useList, useUpdate } from "@pankod/refine-core";

import "./style.css";

export const TrainingEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult, mutationResult, form, redirect } =
    useForm<ITraining>();

  const categoryDataResult = useList({
    resource: "category",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });
  // @ts-ignore
  const categoryData = categoryDataResult?.data?.data?.results;

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { Option } = Select;
  const { buttonProps, uploadProps } = useImport();
  
  // const props: UploadProps = {
  //   name: 'file',
  // };

  const {mutate} = useUpdate()
  const updateData = () => {
    const formData = new FormData();
    formData.set("trainer", form?.getFieldValue("trainer"))
    formData.set("category", form?.getFieldValue("category"))
    formData.set("description", form?.getFieldValue("description"))
    formData.set("difficulty", form?.getFieldValue("difficulty"))
    formData.set("isActive", form?.getFieldValue("isActive"))
    formData.set("isFeatured", form?.getFieldValue("isFeatured"))
    formData.set("quota", form?.getFieldValue("quota"))
    formData.set("title", form?.getFieldValue("title"))
    if(form?.getFieldValue("image").file) {
      formData.set("image", form?.getFieldValue("image").file)
    }

    mutate(
      {
        resource: "session/group/training",
        values: formData,
        id: formProps?.initialValues?.id
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          return redirect("list");
        },
      }
    );
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} onFinish={updateData} layout="vertical">
        <Row gutter={[50, 0]}>
          <Col span={10}>
            <Form.Item label="Eğitmen" name="trainerName">
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="title"
              label="Ders Adı"
              hasFeedback
              rules={[{ required: true, message: "Ders adı zorunlu!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="category"
              label="Kategori"
              hasFeedback
              rules={[{ required: true, message: "Kategori zorunlu!" }]}
            >
              <Select placeholder="Kategori Seç">
                {categoryData &&
                  categoryData.map((i: any) => {
                    return (
                      <Option key={i.name} value={i.id}>
                        {i.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>

            <Row justify="space-between">
              <Col span={10}>
                <Form.Item
                  name="quota"
                  label="Kota"
                  hasFeedback
                  rules={[{ required: true, message: "Kota zorunlu!" }]}
                >
                  <InputNumber className="number-input" min={0} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="difficulty"
                  label="Zorluk Derecesi"
                  hasFeedback
                  rules={[
                    { required: true, message: "Zorluk derecesi zorunlu!" },
                  ]}
                >
                  <Select placeholder="Zorluk derecesi">
                    <Option value={0}>Başlangıç</Option>
                    <Option value={1}>Kolay</Option>
                    <Option value={2}>Orta</Option>
                    <Option value={3}>Zor</Option>
                    <Option value={4}>Uzman</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col span={10}>
                <Form.Item
                  name="isActive"
                  label="Ders Durumu"
                  rules={[{ required: true, message: "Durum zorunlu!" }]}
                >
                  <Select className="number-input" placeholder="Durum seçiniz.">
                    <Option value={true}>Aktif</Option>
                    <Option value={false}>Kapalı</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="isFeatured"
                  label="Öne Çıkarılma"
                  rules={[{ required: true, message: "Durum zorunlu!" }]}
                >
                  <Select className="number-input" placeholder="Durum seçiniz.">
                    <Option value={true}>Aktif</Option>
                    <Option value={false}>Kapalı</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Ders Açıklaması"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Açıklama zorunlu!",
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            <Form.Item name="image">
              <Upload {...uploadProps} accept=".jpg" maxCount={1}>
                <Button icon={<Icons.UploadOutlined />} {...buttonProps}>
                  Resim Ekle
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
