import { useState } from "react";

import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  Row,
  Col, Checkbox
} from "@pankod/refine-antd";
import { ICustomer } from "interfaces/customer";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

const {Option} = Select

export const CustomerEdit: React.FC = () => {
  const { formProps, saveButtonProps,onFinish,form } =
    useForm<ICustomer>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Row>
        <Col span={12}>
        <Form {...formProps} layout="vertical">
        <Form.Item label="Kullanıcı Adı" name="username">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Ad"
          name="firstName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Soyad"
          name="lastName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Yaş"
          name="age"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

          <Form.Item
              label="Aktif"
              name="isActive"
              rules={[
                {
                  required: true,
                },
              ]}
          >
              <Checkbox
                  onChange={(e) => {
                    form?.setFieldsValue({isActive: e.target.checked})
                  }}
                defaultChecked={formProps?.initialValues?.isActive}
                value={formProps?.initialValues?.isActive}

              />
          </Form.Item>

        <Form.Item
          label="Cinsiyet"
          name="gender"
        >
          <Select defaultValue={formProps?.initialValues?.gender}>
            <Option value={'male'}>Erkek</Option>
            <Option value={'female'}>Kadın</Option>
            <Option value={'other'}>Diğer</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Telefon Numarası"
          name="phoneNumber"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
        </Col>
      </Row>
    </Edit>
  );
};
