import { useList, useCreate } from "@pankod/refine-core";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Typography,
  InputNumber,
  Button,
  message,
  Upload,
  Icons,
  Checkbox,
  Create,
  useForm,
  useSelect,
  useImport,
} from "@pankod/refine-antd";

import type { UploadProps } from "@pankod/refine-antd";

import { ITrainer } from "interfaces/trainer";
import { ITraining, ICategory } from "interfaces/training";

import "./style.css";

import React, { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

export const CreateTraining = () => {
  const { TextArea } = Input;
  const { Option } = Select;
  const { Title } = Typography;

  const {
    formProps,
    saveButtonProps,
    queryResult,
    form,
    onFinish,
    formLoading,
    mutationResult,
    redirect,
  } = useForm<ITraining>();

  const trainerDataResult = useList<ITrainer>({
    resource: "trainer",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });

  const categoryDataResult = useList<ICategory>({
    resource: "category",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const { buttonProps, uploadProps } = useImport();
  // const props: UploadProps = {
  //   name: 'file',
  // };

  const { mutate } = useCreate();
  // @ts-ignore
  const trainerData = trainerDataResult?.data?.data?.results;

  // @ts-ignore
  const categoryData = categoryDataResult?.data?.data?.results;

  const saveData = () => {
    const formData = new FormData();
    formData.append("trainer", form?.getFieldValue("trainer"));
    formData.append("category", form?.getFieldValue("category"));
    formData.append("description", form?.getFieldValue("description"));
    formData.append("difficulty", form?.getFieldValue("difficulty"));
    formData.append("isActive", form?.getFieldValue("isActive"));
    formData.append("isFeatured", form?.getFieldValue("isFeatured"));
    formData.append("quota", form?.getFieldValue("quota"));
    formData.append("title", form?.getFieldValue("title"));
    if (form?.getFieldValue("image")) {
      form?.getFieldValue("image").file && formData.append("image", form?.getFieldValue("image").file);
    }

    const formData2 = {
      trainer: form?.getFieldValue("trainer"),
      category: form?.getFieldValue("category"),
      description: form?.getFieldValue("description"),
      difficulty: form?.getFieldValue("difficulty"),
      isActive: form?.getFieldValue("isActive"),
      isFeatured: form?.getFieldValue("isFeatured"),
      quota: form?.getFieldValue("quota"),
      title: form?.getFieldValue("title"),
    } as ITraining;

    mutate(
      {
        resource: "session/group/training",
        values: formData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          return redirect("list");
        },
      }
    );
  };

  return (
    <div className="training-form-box">
      <Create saveButtonProps={{ ...saveButtonProps }}>
        <Form {...formProps} onFinish={saveData} layout="vertical">
          <Row justify="center">
            <Col span={12}>
              <div className="form-col">
                <Form.Item
                  name="trainer"
                  label="Eğitmen"
                  hasFeedback
                  rules={[{ required: true, message: "Eğitmen zorunlu!" }]}
                >
                  <Select placeholder="Eğitmen Seç">
                    {trainerData &&
                      trainerData.map((i: any) => {
                        return (
                          <Option key={i.username} value={i.id}>
                            {i.firstName + " " + i.lastName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="category"
                  label="Kategori"
                  hasFeedback
                  rules={[{ required: true, message: "Kategori zorunlu!" }]}
                >
                  <Select placeholder="Kategori Seç">
                    {categoryData &&
                      categoryData.map((i: any) => {
                        return (
                          <Option key={i.name} value={i.id}>
                            {i.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="title"
                  label="Ders Adı"
                  hasFeedback
                  rules={[{ required: true, message: "Ders adı zorunlu!" }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Ders Açıklaması"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Açıklama zorunlu!",
                    },
                  ]}
                >
                  <ReactMde
                    selectedTab={selectedTab}
                    onTabChange={setSelectedTab}
                    generateMarkdownPreview={(markdown) =>
                      Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                    }
                  />
                </Form.Item>

                <Form.Item name="image">
                  <Upload {...uploadProps} accept=".jpg" maxCount={1}>
                    <Button icon={<Icons.UploadOutlined />} {...buttonProps}>
                      Resim Ekle
                    </Button>
                  </Upload>
                </Form.Item>

                <Row justify="space-between">
                  <Col span={10}>
                    <Form.Item
                      name="quota"
                      label="Kota"
                      hasFeedback
                      rules={[{ required: true, message: "Kota zorunlu!" }]}
                    >
                      <InputNumber className="number-input" min={0} />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="difficulty"
                      label="Zorluk Derecesi"
                      hasFeedback
                      rules={[
                        { required: true, message: "Zorluk derecesi zorunlu!" },
                      ]}
                    >
                      <Select placeholder="Zorluk derecesi">
                        <Option value={0}>Başlangıç</Option>
                        <Option value={1}>Kolay</Option>
                        <Option value={2}>Orta</Option>
                        <Option value={3}>Zor</Option>
                        <Option value={4}>Uzman</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={10}>
                    <Form.Item
                      name="isActive"
                      label="Ders Durumu"
                      rules={[{ required: true, message: "Durum zorunlu!" }]}
                    >
                      <Select
                        className="number-input"
                        placeholder="Durum seçiniz."
                      >
                        <Option value={true}>Aktif</Option>
                        <Option value={false}>Kapalı</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="isFeatured"
                      label="Öne Çıkarılma"
                      rules={[{ required: true, message: "Durum zorunlu!" }]}
                    >
                      <Select
                        className="number-input"
                        placeholder="Durum seçiniz."
                      >
                        <Option value={true}>Aktif</Option>
                        <Option value={false}>Kapalı</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </Create>
    </div>
  );
};
