import {
  List,
  DateField,
  Table,
  useTable,
  ShowButton,
  EditButton,
  Space,
  DeleteButton,
  getDefaultSortOrder,
  Tag,
  CreateButton,
  Typography,
} from "@pankod/refine-antd";

import { ITraining } from "interfaces/training";

export const TrainingList: React.FC = () => {
  const { tableProps, sorter } = useTable<ITraining>({
    initialPageSize: 10,
  });

  let resultData: any = tableProps?.dataSource;
  let result = resultData?.results;

  let total = resultData?.count;
  let paginationData: any = tableProps?.pagination;
  let overrideProps = {
    ...tableProps,
    dataSource: result,
    total,
    page: paginationData?.current,
  };

  return (
    <>
      <List pageHeaderProps={{ extra: <CreateButton /> }}>
        <Table {...overrideProps} rowKey="id" showSorterTooltip={true} sortDirections={['ascend']}>
          <Table.Column
            dataIndex="isActive"
            title="Durum"
            render={(value) => (
              <Tag color={value === true ? "success" : "error"}>
                {value === true ? "AKTİF" : "KAPALI"}
              </Tag>
            )}
          />
          <Table.Column
            dataIndex="title"
            title="Başlık"
            sorter
          />
          <Table.Column
            dataIndex="trainerName"
            title="Eğitmen"
            sorter
          />
          <Table.Column
            dataIndex="categoryName"
            title="Kategori"
          />
          <Table.Column
            dataIndex="difficulty"
            title="Zorluk Derecesi"
            sorter
            render={(value: number) => {
              return value === 0 ? (
                <Typography.Text type="success">Başlangıç</Typography.Text>
              ) : value === 1 ? (
                <Typography.Text type="success">Kolay</Typography.Text>
              ) : value === 2 ? (
                <Typography.Text type="warning">Orta</Typography.Text>
              ) : value === 3 ? (
                <Typography.Text type="danger">Zor</Typography.Text>
              ) : value === 4 ? (
                <Typography.Text type="danger">Uzman</Typography.Text>
              ) : (
                <Typography.Text type="secondary">
                  Belirtilmemiş
                </Typography.Text>
              );
            }}
          />
          <Table.Column
            dataIndex="quota"
            title="Kota"
            sorter
          />

          <Table.Column
            dataIndex="favoritedBy"
            title="Favori"
            render={(value) => {
              return value.length + " kişi";
            }}
          />
          <Table.Column<ITraining>
            title="Seçenekler"
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton size="middle" recordItemId={record.id} hideText />
                  <EditButton size="middle" recordItemId={record.id} hideText />
                  <DeleteButton
                    size="middle"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
