import { useState } from "react";

import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  DatePicker,
  DateField,
  Button,
} from "@pankod/refine-antd";
import { ISessionGroup } from "interfaces/session";
import { ITraining } from "interfaces/training";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useList } from "@pankod/refine-core";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const GroupEdit: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<ISessionGroup>();

  const categoryDataResult = useList<ITraining>({
    resource: "session/group/training",
    config: {
      pagination: { current: 1, pageSize: 1000 },
      hasPagination: true,
    },
  });

  // @ts-ignore
  const categoryData = categoryDataResult?.data?.data?.results;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Durum" name="isCanceled">
          <Select defaultValue={formProps?.initialValues?.isCanceled}>
            <Option value={true}>Kapalı</Option>
            <Option value={false}>Açık</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Ders" name="groupTraining">
          <Select defaultValue={formProps?.initialValues?.groupTrainingName}>
            {categoryData &&
              categoryData.map((i: ITraining) => {
                return (
                  <Option key={i.id} value={i.id}>
                    {i.title}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="Oluşturulma Tarihi" name="createdAt">
          <DateField
            format="LLL"
            locales="tr"
            value={formProps?.initialValues?.createdAt}
          />
        </Form.Item>

        <Form.Item label="Başlama Tarihi" name="createdAt">
          <div
            className="date-box"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="date-format-box" style={{ width: "350px" }}>
              <DateField
                format="LLL"
                locales="tr"
                value={formProps?.initialValues?.startTime}
              />
            </div>
          </div>
        </Form.Item>

        <Form.Item label="Bitiş Tarihi" name="createdAt">
          <div
            className="date-box"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="date-format-box" style={{ width: "350px" }}>
              <DateField
                format="LLL"
                locales="tr"
                value={formProps?.initialValues?.endTime}
              />
            </div>
          </div>
        </Form.Item>
      </Form>
    </Edit>
  );
};
