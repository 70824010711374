import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  Row,
  Col,
} from "@pankod/refine-antd";

import { useUpdate } from "@pankod/refine-core";

import { IOrder } from "interfaces/order";

export const OrderEdit: React.FC = () => {
  const { mutate: updateMutate } = useUpdate();
  const { formProps, saveButtonProps, form, redirect } = useForm<IOrder>();
  const { Option } = Select;

  const cities = [
    "ADANA",
    "ADIYAMAN",
    "AFYON",
    "AGRI",
    "AKSARAY",
    "AMASYA",
    "ANKARA",
    "ANTALYA",
    "ARDAHAN",
    "ARTVIN",
    "AYDIN",
    "BALIKESIR",
    "BARTIN",
    "BATMAN",
    "BAYBURT",
    "BILECIK",
    "BINGOL",
    "BITLIS",
    "BOLU",
    "BURDUR",
    "BURSA",
    "CANAKKALE",
    "CANKIRI",
    "CORUM",
    "DENIZLI",
    "DIYARBAKIR",
    "DUZCE",
    "EDIRNE",
    "ELAZIG",
    "ERZINCAN",
    "ERZURUM",
    "ESKISEHIR",
    "GAZIANTEP",
    "GIRESUN",
    "GUMUSHANE",
    "HAKKARI",
    "HATAY",
    "IGDIR",
    "ISPARTA",
    "ISTANBUL",
    "IZMIR",
    "KAHRAMANMARAS",
    "KARABUK",
    "KARAMAN",
    "KARS",
    "KASTAMONU",
    "KAYSERI",
    "KILIS",
    "KIRIKKALE",
    "KIRKLARELI",
    "KIRSEHIR",
    "KOCAELI",
    "KONYA",
    "KUTAHYA",
    "MALATYA",
    "MANISA",
    "MARDIN",
    "MERSIN",
    "MUGLA",
    "MUS",
    "NEVSEHIR",
    "NIGDE",
    "ORDU",
    "OSMANIYE",
    "RIZE",
    "SAKARYA",
    "SAMSUN",
    "SANLIURFA",
    "SIIRT",
    "SINOP",
    "SIRNAK",
    "SIVAS",
    "TEKIRDAG",
    "TOKAT",
    "TRABZON",
    "TUNCELI",
    "USAK",
    "VAN",
    "YALOVA",
    "YOZGAT",
    "ZONGULDAK",
  ];

  const saveProps = () => {
    const orderData = {
      orderCargoNumber: form?.getFieldValue("orderCargoNumber"),
      orderStatus: form?.getFieldValue("orderStatus"),
      orderCity: form?.getFieldValue("orderCity"),
      orderZip: form?.getFieldValue("orderZip"),
      orderAddress: form?.getFieldValue("orderAddress"),
    };

    updateMutate(
      {
        resource: "order",
        id: formProps?.initialValues?.id,
        values: orderData,
      },
      {
        onError: (error, variables, context) => {
          return error.message;
        },
        onSuccess: (data, variables, context) => {
          return redirect("list");
        },
      }
    );
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical" onFinish={saveProps}>
        <Row gutter={[50, 0]} justify={"center"}>
          <Col span={12}>
            <Form.Item label="Sipariş Durumu" name="orderStatus">
              <Select>
                <Option value={"PENDING"}>Beklemede</Option>
                <Option value={"PREPARING"}>Hazırlanıyor</Option>
                <Option value={"SHIPPED"}>Kargoya Verildi</Option>
                <Option value={"DELIVERED"}>Teslim Edilmiş</Option>
                <Option value={"CANCELLED"}>İptal Edilmiş</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Kargo No"
              name="orderCargoNumber"
              rules={[
                {
                  required: true,
                  message: "Kargo numarası zorunlu!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Şehir" name="orderCity">
              <Select>
                {cities.map((city) => (
                  <Option value={city}>{city}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Posta Kodu"
              name="orderZip"
              rules={[
                {
                  required: true,
                  message: "Posta kodu zorunlu!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Adres"
              name="orderAddress"
              rules={[
                {
                  required: true,
                  message: "Adres zorunlu!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
