import {
  List,
  DateField,
  Table,
  useTable,
  ShowButton,
  EditButton,
  Space,
  DeleteButton,
  getDefaultSortOrder,
  Tag,
  CreateButton,
  Button,
  Typography
} from "@pankod/refine-antd";
import { useList, useOne } from "@pankod/refine-core";

import { ICustomer } from "interfaces/customer";
import { ICategory, ITraining } from "interfaces/training";
import { useEffect } from "react";

export const GroupList: React.FC = () => {
  const { tableProps, sorter } = useTable<ICustomer>({
    initialPageSize: 10,
  });

  let resultData: any = tableProps?.dataSource;
  let result = resultData?.results;

  let total = resultData?.count;
  let paginationData: any = tableProps?.pagination;
  let overrideProps = {
    ...tableProps,
    dataSource: result,
    total,
    page: paginationData?.current,
  };

  return (
    <>
      <List>
        <Table {...overrideProps} rowKey="id">
          <Table.Column
            dataIndex="isCanceled"
            title="Durum"
            render={(value) => (
              <Tag color={value === true ? "error" : "success"}>
                {value === true ? "KAPALI" : "AÇIK"}
              </Tag>
            )}
          />

          <Table.Column
            dataIndex="groupTrainingName"
            title="Ders"
          />

          <Table.Column
            dataIndex="startTime"
            title="Başlama Tarihi"
            render={(value) => (
              <DateField format="LLL" locales="tr" value={value} />
            )}
          />
          <Table.Column
            dataIndex="endTime"
            title="Bitiş Tarihi"
            render={(value) => (
              <DateField format="LLL" locales="tr" value={value} />
            )}
          />
          <Table.Column
            dataIndex="customers"
            title="Satın Alan"
            render={(value) => value.length + " kişi"}
          />
          <Table.Column
            dataIndex="participations"
            title="Katılan"
            render={(value) => value.length + " kişi"}
          />
          <Table.Column<ICustomer>
            title="Seçenekler"
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton size="middle" recordItemId={record.id} hideText />
                  <EditButton size="middle" recordItemId={record.id} hideText />
                  <DeleteButton
                    size="middle"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
